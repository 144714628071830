
import YolYardim from '../../../assets/img/hizmetler/hizmetler_yolyardim.jpg'
const HizmetlerYolYardim = () => {
    return (
        <>
            <div className="o-container o-container--page u-relative">
                <div className="o-container__wrapper">
                    <div className="page-content">
                        <>
                        <img alt="Mobil Assistance Yol Yardım" className="align-right" src={YolYardim} />
                            <p>Hızlı ve Güvenilir Yol Yardımı ve Araç Çekme Hizmeti</p>
                            <p>
                            Yolda kalmak her sürücünün başına gelebilecek bir durumdur. 
                            </p>
                            <p>
                            İster uzun bir seyahatte olun isterse günlük işlerinizi hallederken olsun, aracınızın beklenmedik şekilde bozulması veya kazaya uğraması can sıkıcı olabilir. Bu tür durumlarda size gereken en önemli şey hızlı ve güvenilir yol yardımıdır. 
                            </p>
                            <p>
                            Mobil Assistance Yol Yardım Hizmetleri, yolda kalan sürücülere profesyonel yol yardımı ve araç çekme hizmetleri sunan lider bir firma olarak faaliyet göstermektedir.
                            </p>
                            <p>
                            Deneyimli çağrı merkezi ve teknik destek ekibimizle 7/24 hizmet vererek acil durumlarınıza anında müdahale ediyoruz.
                            </p>
                            <p>
                            Ekiplerimizin hepsi alanında uzmanlaşmış eğitimli profesyonellerdir. Teknolojiye uyum sağlayan donanımlarımız sayesinde sorunlarınıza anında çözüm getirebiliyoruz. Ayrıca, geniş araç filomuz sayesinde her türden aracı rahatlıkla taşıyabiliyoruz. 
                            </p>
                            <p>
                            Güvenlik bizim için en önemli konudur. Tüm müşterilerimize geliştirdiğimiz güvenlik tedbirleri ile hassas davranırız ve araçlarını titizlikle koruruz. Ek olarak, ulaşacağımız konuma en kısa sürede varmak için etkili yönlendirme sistemlerine sahibiz. 
                            </p>
                            <p>
                            Bizlere erişmek oldukça kolaydır; çağrı merkezi numaramız 7 gün 24 saat hizmet vermektedir ve talebinize anında yanıt verilmektedir. 
                            </p>
                            <p>
                            Sizi yolda bırakmamak adına geniş bir çalışma ağına sahibiz; böylece Türkiye'nin neresinde olursanız olun yolda kaldığınızda size en yakın araç kurtarma ekibini gönderebiliriz. Yolculuklar sırasında her an başınıza gelebilecek olumsuzluklar karşısında kendinizi güvende hissetmek istiyorsanız, bize ulaşabilirsiniz.
                            </p>
                            <p>
                            Profesyonel ekibimiz ve hızlı hizmet anlayışımızla en kısa sürede size yardımcı olmak için buradayız. Güvenilir yol yardımı ve araç çekme hizmeti için tercih edeceğiniz firma olarak kaliteli, etkili ve uygun fiyatlı çözümler sunuyoruz. 
                            </p>
                            <p>
                            Sizi yolda asla yalnız bırakmayacak olan Mobil Assistance Yol Yardım Hizmetleri olarak hedefimiz; sorunlarınızın en kısa sürede çözülmesini sağlamaktır.
                            </p>
                            <p>
                            Yolculuğunuz sırasında yaşayabileceğiniz her türlü sorunda sizin yanınızda olan Mobil Assistance Yol Yardım Hizmetleri, müşteri memnuniyetini ilke edinmiştir. Size sunduğumuz profesyonel hizmetlerle güvenli bir yolculuk deneyimi yaşamanız için elimizden geleni yapıyoruz.
                            </p> 
                            <p> 
                                Bizi tercih ettiğinizde şunları elde edersiniz:
                            </p>
                            <li>Hızlı ve etkili müdahale </li>
                            <li>Profesyonel ekip </li> 
                            <li>7/24 kesintisiz hizmet </li> 
                            <p>
                            Sorunsuz bir yolculuk deneyimi için bize ulaşarak en iyi yol yardım ve araç çekme hizmetini alabilirsiniz. Yol yardımının lider ismi olarak sektördeki tecrübemizi sizlerle paylaşıyor ve her zaman yanınızda olduğumuzu hatırlatmak istiyoruz. Bize güvenebilirsiniz!
                            </p>
                        </>

                    </div>
                </div >
            </div >
        </>
    );
};

export default HizmetlerYolYardim;