

import icon from '../assets/img/MobilAssistance_icon.png'
import svg_yolyardim from '../assets/img/svg/yolyardim_svg.svg'
import svg_ikamearac from '../assets/img/svg/ikamearac_svg.svg'
import svg_callcenter from '../assets/img/svg/cagrimerkezi_svg.svg'
import svg_kisiyeozel from '../assets/img/svg/kisiyeozel_svg.svg'

// mobil assistance
import slide1_1 from '../assets/img/slider/slider1/slide-1-arac.png'
import slide1_2 from '../assets/img/slider/slider1/slide-1-slogan.png'
import slide1_3 from '../assets/img/slider/slider1/slide-1-image.png'


import slide2_1 from '../assets/img/slider/slider1/slide-2-arac.png'
import slide2_2 from '../assets/img/slider/slider1/slide-2-slogan.png'
import slide2_3 from '../assets/img/slider/slider1/slide-2-image.png'

import slide3_1 from '../assets/img/slider/slider1/slide-3-arac.png'
import slide3_2 from '../assets/img/slider/slider1/slide-3-slogan.png'
import slide3_3 from '../assets/img/slider/slider1/slide-3-image.png'

// Çağrı Merkezi
import slide4_1 from '../assets/img/slider/slider1/slide-4-arac.png'
import slide4_2 from '../assets/img/slider/slider1/slide-4-slogan.png'
import slide4_3 from '../assets/img/slider/slider1/slide-4-image.png'

// Özel h,zmetler

import slide5_1 from '../assets/img/slider/slider1/slide-5-arac.png'
import slide5_2 from '../assets/img/slider/slider1/slide-5-slogan.png'
import slide5_3 from '../assets/img/slider/slider1/slide-5-image.png'


import { Navigation, Pagination, Autoplay, EffectCube, Thumbs } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination'; 
import 'swiper/css/autoplay';


import { useState } from 'react'

const Slider = () => {

    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    function Slide1() {
        return (
            <>
                <div className="bg bg2" >
                    <div className='row'>
                        <div className='col-lg-4 col-md-4 content-item'>
                            <div data-aos="fade-right" data-aos-delay="900" className='slide-image-left'>
                                <img src={slide1_2} alt='Mobil Assistance'></img>
                            </div>
                            <div data-aos="fade-right" data-aos-delay="1000" className='slide-image-left2'>
                                <img src={slide1_1} alt='Mobil Assistance'></img>
                            </div>
                        </div>
                        <div className='col-lg-8 col-md-8 content-item'>
                            <div className='slide-image-right' data-aos="fade-left" data-aos-delay="900" >
                                <img src={slide1_3} alt='Mobil Assistance'></img>
                            </div>
                        </div>
                    </div>
                    <div className="c-full-slider__caption">
                        <div className="c-full-slider__actions" >
                            <a className="c-button c-button--border c-button--white" href="/tr/kurumsal/mobil-assistance-kurumsal">
                                <span className="c-button__text">BİZİ TANIYIN</span>
                            </a>
                        </div>
                    </div>
                    {/* </div> */}
                </div>
            </>
        )
    }
    function Slide2() {
        return (
            <div className="bg bg2" >
                <div className='row'>
                    <div className='col-lg-4 col-md-4 content-item'>
                        <div data-aos="fade-right" data-aos-delay="900" className='slide-image-left'>
                            <img src={slide2_2} alt='Mobil Assistance'></img>
                        </div>
                        <div data-aos="fade-right" data-aos-delay="1200" className='slide-image-left2'>
                            <img src={slide2_1} alt='Mobil Assistance'></img>
                        </div>
                    </div>
                    <div className='col-lg-8 col-md-8 content-item'>
                        <div className='slide-image-right' data-aos="fade-left" data-aos-delay="900" >
                            <img src={slide2_3} alt='Mobil Assistance'></img>
                        </div>
                    </div>
                </div>
                <div className="c-full-slider__caption">
                    <div className="c-full-slider__actions" >
                        <a className="c-button c-button--border c-button--white" href="/tr/hizmetlerimiz/yol-yardim-kurtarma-hizmeti">
                            <span className="c-button__text"> DAHA FAZLASI</span>
                        </a>
                    </div>
                </div>
                {/* </div> */}
            </div>
        )
    }
    function Slide3() {
        return (
            <div className="bg bg2" >
                <div className='row'>
                    <div className='col-lg-4 col-md-4 content-item'>
                        <div data-aos="fade-right" data-aos-delay="900" className='slide-image-left'>
                            <img src={slide3_2} alt='Mobil Assistance'></img>
                        </div>
                        <div data-aos="fade-right" data-aos-delay="1200" className='slide-image-left2'>
                            <img src={slide3_1} alt='Mobil Assistance'></img>
                        </div>
                    </div>
                    <div className='col-lg-8 col-md-8 content-item'>
                        <div className='slide-image-right' data-aos="fade-left" data-aos-delay="900" >
                            <img src={slide3_3} alt='Mobil Assistance'></img>
                        </div>
                    </div>
                </div>
                <div className="c-full-slider__caption">
                    <div className="c-full-slider__actions" >
                        <a className="c-button c-button--border c-button--white" href="/tr/hizmetlerimiz/ikame-arac-hizmeti">
                            <span className="c-button__text">DAHA FAZLASI</span>
                        </a>
                    </div>
                </div>
                {/* </div> */}
            </div>
        )
    }
    function Slide4() {
        return (
            <div className="bg bg2" >
                <div className='row'>
                    <div className='col-lg-4 col-md-4 content-item'>
                        <div data-aos="fade-right" data-aos-delay="900" className='slide-image-left'>
                            <img src={slide4_2} alt='Mobil Assistance'></img>
                        </div>
                        <div data-aos="fade-right" data-aos-delay="1200" className='slide-image-left2'>
                            <img src={slide4_1} alt='Mobil Assistance'></img>
                        </div>
                    </div>
                    <div className='col-lg-8 col-md-8 content-item'>
                        <div className='slide-image-right' data-aos="fade-left" data-aos-delay="900" >
                            <img src={slide4_3} alt='Mobil Assistance'></img>
                        </div>
                    </div>
                </div>
                <div className="c-full-slider__caption">
                    <div className="c-full-slider__actions" >
                        <a className="c-button c-button--border c-button--white" href="/tr/hizmetlerimiz/cagri-merkezi-hizmeti">
                            <span className="c-button__text">DAHA FAZLASI</span>
                        </a>
                    </div>
                </div>
                {/* </div> */}
            </div>
        )
    }
    function Slide5() {
        return (
            <div className="bg bg2" >
                <div className='row'>
                    <div className='col-lg-4 col-md-4 content-item'>
                        <div data-aos="fade-right" data-aos-delay="900" className='slide-image-left'>
                            <img src={slide5_2} alt='Mobil Assistance'></img>
                        </div>
                        <div data-aos="fade-right" data-aos-delay="1200" className='slide-image-left2'>
                            <img src={slide5_1} alt='Mobil Assistance'></img>
                        </div>
                    </div>
                    <div className='col-lg-8 col-md-8 content-item'>
                        <div className='slide-image-right' data-aos="fade-left" data-aos-delay="900" >
                            <img src={slide5_3} alt='Mobil Assistance'></img>
                        </div>
                    </div>
                </div>
                <div className="c-full-slider__caption">
                    <div className="c-full-slider__actions" >
                        <a className="c-button c-button--border c-button--white" href="/tr/hizmetlerimiz/kisiye-ozel-hizmet-paketleri">
                            <span className="c-button__text">DAHA FAZLASI</span>
                        </a>
                    </div>
                </div>
                {/* </div> */}
            </div>
        )
    } 

    return (
        <>
            <section className="o-section" >
                <Swiper
                    // install Swiper modules
                    modules={[Navigation, Pagination, Autoplay, EffectCube, Thumbs]}
                    spaceBetween={50}
                    slidesPerView={1}
                    navigation
                    autoplay={{
                        delay: 6500,
                        disableOnInteraction: false,
                    }}
                    loop={true}
                    // pagination={{ clickable: true }}
                    // onSwiper={(swiper) => console.log(swiper)}
                    // onSlideChange={() => console.log('slide change')}
                    effect={'fade'}
                    grabCursor={true}
                    // cubeEffect={{
                    //     shadow: false,
                    //     slideShadows: false,
                    //     shadowOffset: -50,
                    //     shadowScale: 0.64,
                    // }}
                    thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
                >
                    <SwiperSlide>
                        {({ isActive }) => (
                            <div>
                                {isActive ?
                                    <Slide1 /> :
                                    <></>}
                                { }
                            </div>
                        )}
                    </SwiperSlide>
                    <SwiperSlide>
                        {({ isActive }) => (
                            <>
                                {isActive ?
                                    <Slide2 /> :
                                    <></>}
                                { }
                            </>
                        )}
                    </SwiperSlide>
                    <SwiperSlide>
                        {({ isActive }) => (
                            <>
                                {isActive ?
                                    <Slide3 /> :
                                    <></>}
                                { }
                            </>
                        )}
                    </SwiperSlide>
                    <SwiperSlide>
                        {({ isActive }) => (
                            <>
                                {isActive ?
                                    <Slide4 /> :
                                    <></>}
                                { }
                            </>
                        )}
                    </SwiperSlide>
                    <SwiperSlide>
                        {({ isActive }) => (
                            <>
                                {isActive ?
                                    <Slide5 /> :
                                    <></>}
                                { }
                            </>
                        )}
                    </SwiperSlide>
                </Swiper>
                <div className="c-full-slider__bullets">
                    <div className="o-container">
                        <div className="c-full-slider__bullets__wrapper">
                            <Swiper
                                onSwiper={setThumbsSwiper}
                                loop={true}
                                spaceBetween={50}
                                slidesPerView={5}
                                watchSlidesProgress={true}
                                className="mySwiper"
                            // slideActiveClass='js-active'
                            >
                                <SwiperSlide>
                                    <div className="c-full-slider__bullet--container">
                                        <div className="c-full-slider__bullet">
                                            <img src={icon} alt='Yol Yardım' width={42}></img>
                                        </div>
                                        {/* <span className="title" data-aos="fade-down">Yol Yardım Hizmetleri</span> */}
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="c-full-slider__bullet--container">
                                        <div className="c-full-slider__bullet">
                                            <img src={svg_yolyardim} alt='Yol Yardım' width={42}></img>
                                        </div>
                                        {/* <span className="title"
                                            data-aos="fade-down">Yol Yardım Hizmetleri</span> */}
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="c-full-slider__bullet--container">
                                        <div className="c-full-slider__bullet">
                                            <img src={svg_ikamearac} alt='İkame Araç' width={42}></img>
                                        </div>
                                        {/* <span className="title"
                                            data-aos="fade-down">İkame Araç Hizmetleri</span> */}
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="c-full-slider__bullet--container">
                                        <div className="c-full-slider__bullet"> 
                                                <img src={svg_callcenter} alt='İkame Araç' width={42}></img> 
                                        </div>
                                        {/* <span className="title" data-aos="fade-down">Çağrı Merkezi</span> */}
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="c-full-slider__bullet--container">
                                        <div className="c-full-slider__bullet">
                                            <img src={svg_kisiyeozel} alt='İkame Araç' width={42}></img>
                                        </div>
                                        {/* <span className="title" data-aos="fade-down">Kişiye Özel Hizmet Paketleri</span> */}
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Slider;