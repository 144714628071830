import '../../../App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

import Topbar from '../../../component/topbar';
import Faaliyet from '../../../component/faaliyet';
import Footer from '../../../component/footer';
import Kurumsal from '../../../component/Pages/kurumsal/kurumsal'; 
import Breadcrumb from "../../../component/application/breadcrums";
import PageContainer from '../../../component/application/pageContainer';

const BCrumb = [
    {
        to: '/',
        title: 'Anasayfa',
    },
    {
        to: '/tr/kurumsal/mobil-assistance-kurumsal',
        title: 'Kurumsal'
    },
    {
        title: 'Mobil Assistance Kurumsal',
    },
];

const KurumsalMain = () => {
    AOS.init();
    return (
        <PageContainer title="Kurumsal" description="Mobil assistance Kurumsal ">
            <Topbar />
            <Breadcrumb title="Mobil Assistance Kurumsal" items={BCrumb} />
            <Kurumsal />
            <Faaliyet />
            <Footer />
        </PageContainer>
    );
};
export default KurumsalMain;
