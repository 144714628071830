
import 'bootstrap/dist/css/bootstrap.min.css'; 
import Faliyet from '../../faaliyet'

const PaketlerMain = () => {
    return (
        <>
        <Faliyet />
        {/* <div className="o-container o-container--page u-relative">
            <div className="o-container__wrapper">
                <div className="page-content">
                     <Faliyet />
                </div>
            </div>
        </div> */}
        </>
    );
};

export default PaketlerMain;