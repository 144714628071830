import React from 'react';
import bg from '../../assets/img/background/bg.png'
import { Link, NavLink } from 'react-router-dom';

const Breadcrumb = ({items, title }) => (
    <> 
        <div className="c-sub-header c-sub-header--page">
             <div className="c-sub-header__wrapper" style={{ backgroundImage: "url(" + bg + ")" }} >
            {/*<div className="c-sub-header__wrapper">*/}
                <div className="c-sub-header__content o-container">
                    <div className="c-sub-header--sub-title c-sub-header--sub-img"></div>
                    <div className="c-sub-header--title">
                        <h1 >{title}</h1> 
                    </div> 
                </div>
            </div>
        </div>
        <div className="o-breadcrumb">
            <div className="o-container">
                <div className="o-breadcrumb__wrapper">
                    <div className="o-breadcrumb__list">
                    {items
                            ? items.map((item) => (
                                <div key={item.title} className="o-breadcrumb__item">

                             <Link className='c-sub-header--desc' component={NavLink} to={item.to}> {item.title} </Link>
                        </div> 
                            ))
                            : ''} 
                    </div>
                </div>
            </div>
        </div>
    </>
);

export default Breadcrumb;
