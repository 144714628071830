import React, { useState } from "react";
import Logo from "../assets/img/MobilAssistance.svg" 
import { Button } from "react-bootstrap";
import Kvkk from "./application/kvkk";
import Modal from 'react-bootstrap/Modal';
import Mss from "./application/mesafelisatissozlesme";
import Sartlar from "./application/sartlar";


export default function Footer() {
  const [modalShow, setModalShow] = useState(false);
  const [modalShowMss, setModalShowMss] = useState(false);
  const [modalShowSartlar, setModalShowSartlar] = useState(false);

  function ModalKvkk(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
       >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Kişisel Verilerin Korunması Kanunu (KVKK) Bilgilendirme Yazısı
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Kvkk />
        </Modal.Body>
        <Modal.Footer>
          <Button className='c-special-button' onClick={() => setModalShow(false)}>Okudum anladım</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  function ModalMss(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShowMss}
       >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Mesafeli Satış Sözleşmesi
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Mss />
        </Modal.Body>
        <Modal.Footer>
          <Button className='c-special-button' onClick={() => setModalShowMss(false)}>Okudum anladım</Button>
        </Modal.Footer>
      </Modal>
    );
  } 

  function ModalSartlar(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShowSartlar}
       >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          Kullanım Koşulları
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Sartlar />
        </Modal.Body>
        <Modal.Footer>
          <Button className='c-special-button' onClick={() => setModalShowSartlar(false)}>Okudum anladım</Button>
        </Modal.Footer>
      </Modal>
    );
  } 
  return (
    <> 
      <section className="o-footer" role="contentinfo">
        <footer>
          <div className="top">
            <div className="pages">
              <ul>
                <img src={Logo} alt="Mobil Assistance"></img>
              </ul>
              <ul>
                <h3>Hakkımızda</h3>
                <li><a className="o-footer__nav__item" href="/tr/kurumsal/mobil-assistance-hakkinda">Amaç</a></li>
                <li> <a className="o-footer__nav__item" href="/tr/kurumsal/kurumsal-hedefler">Kurumsal Hedeflerimiz</a></li>
                <li>  <a className="o-footer__nav__item" href="/tr/kurumsal/tr/kurumsal/kalite-politikamiz">Kalite Politikamız</a></li>
                <li>  <a className="o-footer__nav__item" href="/tr/kurumsal/kalite-belgelerimiz">Belgelerimiz</a></li>
                <li>  <a className="o-footer__nav__item" href="/tr/kurumsal/tarihcemiz">Tarihçemiz</a></li>
                <li>  <a className="o-footer__nav__item" href="/tr/kurumsal/etik-kurallarimiz">Etik Kurallarımız</a></li>
                <li>  <a className="o-footer__nav__item" href="tr/kurumsal/kalite-politikamiz">Kurumsal Kimlik</a></li>
                <li>  <a className="o-footer__nav__item" href="/tr/kurumsal/tanitim-filmi">Tanıtım Filmi</a></li>
                <li>  <a className="o-footer__nav__item" href="/tr/mobil-assistance/insan-kaynaklari">İnsan Kaynakları</a></li>
              </ul>

              <ul>
                <h3>Hizmetlerimiz</h3>
                <li><a className="o-footer__nav__item" href="/tr/hizmetlerimiz/yol-yardim-kurtarma-hizmeti">Yol Yardım  Hizmeti</a></li>
                <li><a className="o-footer__nav__item" href="/tr/hizmetlerimiz/ikame-arac-hizmeti">İkame Araç Hizmeti</a></li>
                <li><a className="o-footer__nav__item" href="/tr/hizmetlerimiz/cagri-merkezi-hizmeti">Çağrı Merkezi  Hizmeti</a></li>
                <li><a className="o-footer__nav__item" href="/tr/hizmetlerimiz/kisiye-ozel-hizmet-paketleri">Kişiye Özel Yol Yardım Paketleri</a></li>
              </ul>

              <ul>
                <h3>Online İşlemler</h3>
                <li><a className="o-footer__nav__item" href="/tr/online-islemler/sozlesme-ara">Sözleşme Ara</a></li>
                <li><a className="o-footer__nav__item" target='_blank' rel='noreferrer' href="https://bayi.mobilassistance.com/Bayi_/Giris">Bayi Girişi</a></li>
                <li><a className="o-footer__nav__item" href="/tr/online-islemler/bayilik-basvurusu">Bayi Başvurusu</a></li>
                <li><a className="o-footer__nav__item" href="/tr/online-islemler/tedarikci-basvurusu">Tedarikçi  Başvurusu</a></li>
                <li><a className="o-footer__nav__item" href="/">Tedarikçi Ağımız</a></li>
                <li><a className="o-footer__nav__item" href="/">Bayi Ağımız</a></li>
                {modalShowMss && (
                  <>
                    <ModalMss />
                  </>
                )}
                <li><a className="o-footer__nav__item" href={() => false} onClick={() => setModalShowMss(true)}>Mesafeli Satış Sözleşmesi</a></li>
                {modalShow && (
                  <>
                    <ModalKvkk />
                  </>
                )}
                <li><a className="o-footer__nav__item" href={() => false} onClick={() => setModalShow(true)}>Kvkk</a></li>
              </ul>
              <ul>
                <h3>İletişim</h3>
                <li><a className="o-footer__nav__item" href="/tr/mobil-assistance/iletisim">İletişim</a></li>
                <li> <a className="o-footer__nav__item" target='_blank' rel='noreferrer' href="https://www.linkedin.com/company/mobil-assistance/">LinkedIn</a></li>
                <li>  <a className="o-footer__nav__item" target='_blank' rel='noreferrer' href="https://www.instagram.com/mobilassistance/">İnstagram</a></li>
                <li>  <a className="o-footer__nav__item" target='_blank' rel='noreferrer' href="https://www.facebook.com/mobilassistance">Facebook</a></li>
                <li>  <a className="o-footer__nav__item" target='_blank' rel='noreferrer' href="https://x.com/mobilassistance">Twitter</a></li>
                <li>  <a className="o-footer__nav__item" target='_blank' rel='noreferrer' href="https://www.youtube.com/channel/UCLg4MfWfFBykwkieU9AKgqA/videos">Youtube</a></li> 
              </ul>
            </div>
          </div> 
          <div className="info">
            <div className="legal">
            {modalShowSartlar && (
                  <>
                    <ModalSartlar />
                  </>
                )}
              <a href={() => false} onClick={() => setModalShowSartlar(true)}>Şartlar ve Koşullar</a><a href={() => false} onClick={() => setModalShow(true)}>Gizlilik Politikası</a>
            </div>
            <div className="copyright">2024 Copyright &copy;Mobil Assistance</div>
          </div>
        </footer>
      </section>
    </>
  );
}
