const Belgelerimiz = () => {
    return (
        <div className="o-container o-container--page u-relative">
            <div className="c-bg-border">
                <div className="o-container">
                    <div className="c-col__row">
                        <div className="c-col-4 c-col"></div>
                        <div className="c-col-4 c-col"></div>
                        <div className="c-col-4 c-col"></div>
                        <div className="c-col-4 c-col"></div>
                    </div>
                </div>
            </div>
            <div className="o-container__wrapper">
                <div className="page-content">
                    <h1>Etik Kurallarımız</h1>
                    <p>
                        Yüksek Standartlarımız ve Güvenilir Hizmet Anlayışımızla Öncüyüz
                    </p>
                    <h1>Yasal Sorumluluklarımız</h1>
                    <p>
                        Mobil Assistance olarak faaliyetlerimizi yerine getirirken yasalar ve uluslararası hukuk kuralları çerçevesinde hareket eder, tüm kamu kurum ve kuruluşlar ile iş birliği içerisinde çalışır ve bu doğrultuda bizden talep edilen her türlü bilgi, belge, doküman ve bildirimleri doğru zamanında ve anlaşılabilir olarak sunarız.
                    </p>
                    <p>
                        Mali mevzuat başta olmak üzere şirketlerimizin faaliyetleriyle ilgili tüm mevzuatın kural ve gerekliliklerine uyulması konusunda hassasiyet gösterir, iş birliği içerisinde olduğumuz üçüncü kişi ve kurumlardan da bunu bekleriz.
                    </p>
                    <h1>“Mobil Assistance” Adına Karşı Sorumluluklarımız</h1>
                    <p>
                        “Mobil Assistance 5 yılı aşkın birikimin ortaya koyduğu değerler bütününü simgelemektedir. Mobil Assistance ’ın her bir çalışanı davranışlarıyla kendi şahsı ile birlikte aslında bu değerler bütününü de temsil etmektedir.
                    </p>
                    <p>
                        Mobil Assistance çalışanları şirketi temsil sırasında standart kurum dokümanlarını kullanmaya, üçüncü kişi ve kurumlarla ilişkilerinde standart bilgilendirmeleri yapmaya gayret ederler. Yorum ve spekülasyonlara yol açacak konuşmalardan şirketin ve verdiği hizmetlerin aleyhine olabilecek doğrudan ve dolaylı ifadeleri kullanmaktan kaçınırlar.
                    </p>
                    <p>
                        Tüm çalışanlarımız, kurumu temsilen konuşulduğu izleniminin doğabileceği sosyal medya ve kamuya açık ortamlarda, kendi kişisel görüşlerini yansıtmaktan kaçınır sadece kurumun görüşlerini dile getirirler.
                    </p>
                    <h1>Müşteri Odaklı Yaklaşım</h1>
                    <p>
                        İlk önceliğimiz her zaman müşteri memnuniyetidir. Müşterilerimizin ihtiyaçlarını anlamak ve onların beklentilerini aşan hizmetler sunmak için sürekli çaba gösteririz.
                    </p>
                    <h1>Şeffaflık ve Dürüstlük</h1>
                    <p>
                        İşimizin her aşamasında şeffaflığı ve dürüstlüğü benimseriz. Müşterilerimize sunacağımız hizmetler ve fiyatlandırmalar konusunda net ve açık bilgi veririz.
                    </p>
                    <h1>Güvenilirlik ve Taahhüt</h1>
                    <p>
                        Verdiğimiz sözleri tutarız ve müşterilerimize karşı her zaman güvenilir bir iş ortağı oluruz. Taahhütlerimizi zamanında ve eksiksiz bir şekilde yerine getiririz.
                    </p>
                    <h1>Eğitim ve Gelişim</h1>
                    <p>
                        Personelimizin sürekli olarak eğitimini sağlayarak, güncel teknolojik gelişmeleri takip etmelerini ve müşterilerimize en iyi hizmeti sunmalarını sağlarız.
                    </p>
                    <h1>Kalite Güvencesi ve Sürekli İyileştirme</h1>
                    <p>
                        Hizmet kalitemizi sürekli olarak değerlendirir ve iyileştirme süreçlerini uygularız. Müşterilerimizden gelen geri bildirimleri dikkate alır ve bu doğrultuda hizmetlerimizi geliştiririz.
                    </p>
                    <h1>Adil Fiyat Politikası ve Ekonomik Çözümler</h1>
                    <p>
                        Şeffaf bir fiyatlandırma politikası izleyerek, müşterilerimize adil ve ekonomik çözümler sunarız. Fiyatlarımızı makul seviyelerde tutar ve ekonomik şartlarda bile müşteri memnuniyetini sağlarız.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Belgelerimiz;