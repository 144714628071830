import '../../../App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

import Topbar from '../../../component/topbar';
import Faaliyet from '../../../component/faaliyet';
import Footer from '../../../component/footer';
import TanitimFilm from '../../../component/Pages/kurumsal/tanitimfilmi'; 

import Breadcrumb from "../../../component/application/breadcrums";
import PageContainer from '../../../component/application/pageContainer';

const BCrumb = [
    {
        to: '/',
        title: 'Anasayfa',
    },
    {
        to: '/tr/kurumsal/mobil-assistance-kurumsal',
        title: 'Kurumsal'
    },
    {
        title: 'Tanıtım Filmi',
    },
];

const Belgeler = () => {
    AOS.init();
    return (
        <PageContainer title="Tanıtım Filmi" description="Mobil assistance Tanıtım Filmi">
            <Topbar />
            <Breadcrumb title="Mobil Assistance Tanıtım Filmi" items={BCrumb} />
            <TanitimFilm />
            <Faaliyet />
            <Footer />
        </PageContainer>
    );
};
export default Belgeler;
