import logo from '../../../../src/kurumsal/MobilAssistance_logo_.png'
import logo_svg from '../../../../src/kurumsal/MobilAssistance_Logo.png'
  

const KurumsalKimlik = () => {
    return (
        <div className="o-container o-container--page u-relative">
            <div className="c-bg-border">
                <div className="o-container">
                    <div className="c-col__row">
                        <div className="c-col-4 c-col"></div>
                        <div className="c-col-4 c-col"></div>
                        <div className="c-col-4 c-col"></div>
                        <div className="c-col-4 c-col"></div>
                    </div>
                </div>
            </div>
            <div className="o-container__wrapper">
                <div className="c-tabs c-tabs--page">
                    <div className="c-tabs__content" >
                        <div className="c-tabs__tab-content" >
                        <div className="c-dl__item" >
                                <div className="left">
                                    <img alt="Mobil Assistance - Logo (SVG)" src={logo} />
                                </div>
                                <div className="right">
                                    <div className="col">
                                        <div className="title">Mobil Assistance - Logo (SVG)</div>
                                    </div>
                                    <div className="col">
                                        <div className="actions">
                                        <a className="c-button"href={require("../../../../src/kurumsal/MobilAssistance.rar")} download={require("../../../../src/kurumsal/MobilAssistance.rar")}> 
                                                <span>İNDİR</span>
                                            </a>
                                            <span>SVG 181 KB</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="c-dl__item" >
                                <div className="left">
                                    <img alt="Mobil Assistance - Logo (SVG)" src={logo} />
                                </div>
                                <div className="right">
                                    <div className="col">
                                        <div className="title">Mobil Assistance - Logo (PNG)</div>
                                    </div>
                                    <div className="col">
                                        <div className="actions">
                                            <a className="c-button" href={logo_svg} download={logo_svg}> 
                                                <span>İNDİR</span>
                                            </a>
                                            <span>PNG 181 KB</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="c-dl__item" >
                                <div className="left">
                                    <img alt="Mobil Assistance - Logo (SVG)" src={logo} />
                                </div>
                                <div className="right">
                                    <div className="col">
                                        <div className="title">Mobil Assistance - Logo (EPS)</div>
                                    </div>
                                    <div className="col">
                                        <div className="actions">
                                            <a className="c-button"href={require("../../../../src/kurumsal/MobilAssistance-EPS.rar")} download={require("../../../../src/kurumsal/MobilAssistance-EPS.rar")}> 
                                                <span>İNDİR</span>
                                            </a>
                                            <span>EPS 2.52 MB</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="c-dl__item" >
                                <div className="left">
                                    <img alt="Mobil Assistance - Logo (SVG)" src={logo} />
                                </div>
                                <div className="right">
                                    <div className="col">
                                        <div className="title">Mobil Assistance - Kurumsal Kimlik Klavuzu (PDF)</div>
                                    </div>
                                    <div className="col">
                                        <div className="actions">
                                            <a className="c-button" href={require("../../../../src/kurumsal/kurumsal-kimlik.rar")} download={require("../../../../src/kurumsal/kurumsal-kimlik.rar")}>
                                                {/* <svg>
                                                <use  ></use>
                                                </svg> */}
                                                <span>İNDİR</span>
                                            </a>
                                            <span>PDF 81.9 MB</span>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default KurumsalKimlik;