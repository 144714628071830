import '../../App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

import Topbar from '../../component/topbar';
import Faaliyet from '../../component/faaliyet';
import Footer from '../../component/footer';
import IletisimForm from '../../component/Pages/iletisim/iletisimform';


import Breadcrumb from "../../component/application/breadcrums";
import PageContainer from '../../component/application/pageContainer';

const BCrumb = [
    {
        to: '/',
        title: 'Anasayfa',
    },
    {
        title: 'İletişim',
    },
    {
        title: 'Mobil Assistance İletişim',
    },
];

const Iletisim = () => {
    AOS.init();
    return (
        <PageContainer title="İletişim" description="Mobil assistance İletişim Form ">
            <Topbar />
            <Breadcrumb title="Mobil Assistance İletişim" items={BCrumb} />
            <IletisimForm />
            <Faaliyet />
            <Footer />
        </PageContainer>
    );
};
export default Iletisim;
