import test from '../../../assets/img/kurumsal/kurumsal_hedefler.jpg'

const hakkimizda = () => {
    return (
        <div className="o-container o-container--page u-relative">
            <div className="c-bg-border">
                <div className="o-container">
                    <div className="c-col__row">
                        <div className="c-col-4 c-col"></div>
                        <div className="c-col-4 c-col"></div>
                        <div className="c-col-4 c-col"></div>
                        <div className="c-col-4 c-col"></div>
                    </div>
                </div>
            </div>
            <div className="o-container__wrapper">
                <div className="page-content">
                    <img alt="Mobil Assistance Kurumsal Değerlerimiz" className="align-right" src={test} />
                     <p>Mobil Assistance Yol Yardım Hizmetleri Olarak Kurumsal Hedeflerimiz</p>
                     <p>
                     Mobil Assistance Yol Yardım Hizmetleri olarak, müşteri odaklı ve yenilikçi hizmet anlayışımızla sektörde öncü olmayı hedefliyoruz. 
                     </p>
                     <p>Doğrudan müşterilerimize ulaşarak, her türlü yol yardımı ve asist hizmetini en etkili şekilde sunmayı taahhüt ediyoruz.</p>
                     <p>
                     1. Müşteri Memnuniyeti Odaklı Hizmet: Müşterilerimizin ihtiyaçlarını anlamak ve onlara en uygun çözümleri sunmak için sürekli olarak çalışıyoruz. Her müşterimizin memnuniyeti, bizim için en büyük önceliktir.
                     </p>
                     <p>
                     2. İnovasyon ve Teknoloji: Teknolojinin gücünü kullanarak süreçlerimizi optimize etmek ve müşterilerimize daha hızlı, daha verimli hizmet sunmak için sürekli olarak yeni çözümler geliştiriyoruz.
                     </p>
                     <p>
                     3. Genişleyen Hizmet Ağı: Türkiye genelinde ve ilerleyen dönemde uluslararası pazarda müşterilerimize kesintisiz hizmet sunabilmek adına hizmet ağımızı sürekli genişletiyoruz.
                     </p>
                     <p>
                     4. Sürdürülebilirlik ve Toplumsal Sorumluluk: Çevresel etkileri en aza indirmek ve toplumsal fayda sağlamak amacıyla sürdürülebilir uygulamaları teşvik ediyoruz. Topluma karşı sorumluluklarımızın bilincinde olarak hareket ediyoruz.
                     </p>
                     <p>
                     5. Çalışan Memnuniyeti ve Gelişimi: Çalışanlarımızın gelişimini destekleyerek, motivasyonlarını artırmak ve işlerindeki başarılarını sürdürmelerini sağlamak için çaba gösteriyoruz. Çalışanlarımızın mutluluğu, başarılarımızın temelini oluşturuyor.
                     </p>
                     <p>
                     6. Değer Yaratma ve Büyüme: Sektördeki liderliğimizi pekiştirerek, değer yaratmaya ve sürdürülebilir büyümeye odaklanıyoruz. Müşterilerimize ve paydaşlarımıza katma değer sağlayarak, uzun vadeli başarılar elde etmeyi amaçlıyoruz.
                     </p>

                </div>
            </div>

        </div>
    );
};

export default hakkimizda;