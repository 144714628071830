import IK from '../../../assets/img/ik/ik_.gif'
const InsanKaynalari = () => {
    return (
        <div className="o-container o-container--page u-relative">
            <div className="o-container__wrapper">
                <div className="page-content">
                    <img alt="Erciyes Anadolu Kurumsal Değerlerimiz" className="align-right" src={IK} />
                    <h1>Prensiplerimiz</h1>
                    <p>
                        Mobil Assistance Yol Yardım Hizmetleri olarak, müşterilerimize ve çalışanlarımıza karşı şeffaf ve güvenilir bir yaklaşım benimsiyoruz.
                    </p>
                    <p>
                        Prensip olarak, aşağıda belirttiğimiz değerlere sıkı sıkıya bağlıyız:
                    </p>
                    <p>
                        Mükemmellik: Her bir müşteriye ve iş ortağımıza sunduğumuz hizmette mükemmeliyeti hedefliyoruz. Müşteri memnuniyeti, en üst düzeyde kalite standartlarımızı koruma konusunda temel önceliğimizdir.                     </p>
                    <p>
                        İnsana Saygı: İşimizin merkezinde, her bir müşterimizin ve çalışanımızın haklarına saygı göstermek vardır. Empati ve dürüstlük, her ilişkimizin temel taşlarıdır.
                    </p>
                    <p>
                        Güvenilirlik: Müşterilerimizin ve iş ortaklarımızın bize duyduğu güveni korumak için adil ve açık bir iletişim anlayışını benimsiyoruz. Söz verdiğimiz her şeyi yerine getirmekten gurur duyuyoruz.                     </p>
                    <p>
                        İnovasyon: Sürekli olarak gelişen ve değişen bir dünyada, yenilikçi çözümler üretmek ve hizmetlerimizi sürekli olarak iyileştirmek için çaba sarf ediyoruz.
                    </p>
                    <p>
                        Çalışan Gelişimi: Çalışanlarımızın gelişimini teşvik ediyor, yeteneklerini ve potansiyellerini ortaya çıkarabilecekleri bir ortam sağlıyoruz.
                    </p>
                    <h1>Bizimle Çalışın</h1>
                    <p>
                        Mobil Assistance Yol Yardım Hizmetleri olarak, dinamik ve başarılı bir ekibin önemini biliyoruz. Siz de bu ekibin bir parçası olmak ve kariyerinizi yol yardım sektöründe ilerletmek istiyorsanız, doğru yerdesiniz.                    </p>
                    <p>
                        Neden Biz?
                    </p>
                    <p>
                        Sektörde Liderlik: Mobil Assistance Yol Yardım Hizmetleri Firması olarak, sektörde öncü bir konumdayız ve hizmet kalitemizle tanınıyoruz.
                    </p>
                    <p>
                        İş Birliği ve Destek: Çalışanlarımızın gelişimi ve başarısı için destekleyici bir çalışma ortamı sağlıyoruz. İş birliği ve takım ruhu bizim için çok önemlidir.
                    </p>
                    <p>
                        Fırsatlar ve Kariyer Gelişimi: Yenilikçi projelerde yer alarak kendinizi sürekli geliştirebileceğiniz bir ortam sunuyoruz.
                    </p>
                    <h1>Başvuru Süreci</h1>
                    <p>
                        Eğer siz de bizimle birlikte çalışmak ve Mobil Assistance Yol Yardım Hizmetleri'nde  kariyer yapmak istiyorsanız, açık pozisyonlarımıza göz atmak ve başvuruda bulunmak için Başvuru Formu’nu doldurabilirsiniz. Ekibimize katılman için sabırsızlanıyoruz!
                    </p>

                </div>
            </div>
        </div>
    );
};

export default InsanKaynalari;