
import 'bootstrap/dist/css/bootstrap.min.css';
import img_yolyardim from '../../../assets/img/hizmetler/hizmetler_yolyardim.jpg'
import img_ikamearac from '../../../assets/img/hizmetler/hizmetler_ikamearac.jpg'
import img_cagrimerkezi from '../../../assets/img/hizmetler/hizmetler_cagrimerkezi.jpg'
import img_kisiyeozel from '../../../assets/img/hizmetler/hizmetler_kisiyeozel.jpg'
 
const HizmetlerGenel = () => {
    return (
        <>
            <div className="o-container o-container--page u-relative">
                <div className="o-container__wrapper">
                    <div className="page-content">
                        <>
                            <div className="c-news-item__list">
                                <div className="c-news-item c-news-item--small-image" data-aos="fade-up" data-aos-delay="600">
                                    <div className="c-news-item__wrapper">
                                        <div className="c-news-item__left">
                                            <div className="c-news-item__image">
                                                <img src={img_yolyardim} alt="Yol Yardım Hizmetleri" />
                                            </div>
                                        </div>
                                        <div className="c-news-item__right"> 
                                            <h2 className="c-news-item__title c-news-item__title--small">Yol Yardım Hizmetleri </h2>
                                            <p className="small">
                                            Aracınızla seyahate çıktığınızda başınıza beklenmedik sorunlar geldi mi hiç? 
                                            <p>Artık bu konuda endişe etmenize gerek yok. </p>
                                            <p>Aracınızla ilgili başınıza gelebilecek her türlü sorunda Yol arkadaşınız yanınızda.</p>
                                            </p>
                                            <div className="c-news-item__actions">
                                                <a className="c-button c-button--border" href="/tr/hizmetlerimiz/yol-yardim-kurtarma-hizmeti"> DETAYLI BİLGİ </a>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </div> 

                            <div className="c-news-item__list">
                                <div className="c-news-item c-news-item--small-image" data-aos="fade-up" data-aos-delay="600">
                                    <div className="c-news-item__wrapper">
                                        <div className="c-news-item__left">
                                            <div className="c-news-item__image">
                                                <img src={img_ikamearac} alt="Yol Yardım Hizmetleri" />
                                            </div>
                                        </div>
                                        <div className="c-news-item__right"> 
                                            <h2 className="c-news-item__title c-news-item__title--small">İkame Araç Hizmetleri </h2>
                                            <p className="small">
                                            Kaza hangi araç sahibinin başına gelmiyor ki! 
                                            <p>Önemli olan bu tip aksilikleri öncelikle en az hasarla ve sağlıkla atlatmak,</p>
                                            <p>Sonrasında ise sizi günlük yaşantınızda bir kesinti olmadan devam ettirecek bir desteğine sahip olmak.</p>
                                            <p>İşte İkame Araç hizmetimizde böylesi bir durumda en kısa sürede ikame araç yönlendirerek konforunuzdan bir eksilme olmamasını sağlıyoruz.</p>
                                            </p>
                                            <div className="c-news-item__actions">
                                                <a className="c-button c-button--border" href="/tr/hizmetlerimiz/ikame-arac-hizmeti"> DETAYLI BİLGİ </a>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </div> 

                            <div className="c-news-item__list">
                                <div className="c-news-item c-news-item--small-image" data-aos="fade-up" data-aos-delay="600">
                                    <div className="c-news-item__wrapper">
                                        <div className="c-news-item__left">
                                            <div className="c-news-item__image">
                                                <img src={img_cagrimerkezi} alt="Yol Yardım Hizmetleri" />
                                            </div>
                                        </div>
                                        <div className="c-news-item__right"> 
                                            <h2 className="c-news-item__title c-news-item__title--small">Çağrı Merkezi Hizmetleri </h2>
                                            <p className="small">
                                            7 gün 24 saat hizmet veren Çağrı Merkezimiz ve tüm ülkede yaygın anlaşmalı hizmet birimleri ağımız ile otomotiv markaları, araç kiralama şirketleri, Sigorta şirketleri, Assistance firmaları için yol yardım, müşteri ilişkileri ve anket hizmetleri veriyoruz.
                                            </p>
                                            <div className="c-news-item__actions">
                                                <a className="c-button c-button--border" href="/tr/hizmetlerimiz/cagri-merkezi-hizmeti"> DETAYLI BİLGİ </a>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </div> 

                            <div className="c-news-item__list">
                                <div className="c-news-item c-news-item--small-image" data-aos="fade-up" data-aos-delay="600">
                                    <div className="c-news-item__wrapper">
                                        <div className="c-news-item__left">
                                            <div className="c-news-item__image">
                                                <img src={img_kisiyeozel} alt="Yol Yardım Hizmetleri" />
                                            </div>
                                        </div>
                                        <div className="c-news-item__right"> 
                                            <h2 className="c-news-item__title c-news-item__title--small">Kişiye Özel Yol Yardım Paketleri </h2>
                                            <p className="small">
                                            İnsan odaklı ve hizmet merkezli bakış açısıyla harekete geçen Mobil Assistance karmaşık prosedür ve taahhütler yerine bağlayıcı olmayan, daha basit ve kişiselleştirilmiş hizmetlere odaklanarak kullanıcılarını en hızlı şekilde en kaliteli çözüme ulaştırıp yaşam standartlarını artırmayı hedefler.
                                            </p>
                                            <div className="c-news-item__actions">
                                                <a className="c-button c-button--border" href="/tr/hizmetlerimiz/kisiye-ozel-hizmet-paketleri"> DETAYLI BİLGİ </a>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </div> 
                        </>
                    </div>
                </div >
            </div >
        </>
    );
};

export default HizmetlerGenel;