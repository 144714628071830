import axios from 'axios';
import React, { useState } from 'react';


const SozlesmeAra = () => {
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const [gelendata, setgelendata] = useState(false)

  const [tcNo, setTcNo] = useState("")
  const [plate, setPlate] = useState("")

  const handleTcChange = (e) => {
    setTcNo(e.target.value);
  }
  const handlePlakaChange = (e) => {
    setPlate(e.target.value); 
  }

  // const data = {
  //   mtTcNo :  {tcNo},
  //   mtPlate :{plate}
  //   // mtTcNo: "10257009756",
  //   // mtPlate: "35ALP929" 
  // };

  const SozlesmeAra = () => {
    // axios.post('https://app.mobilinfo.net/website-service/api/app/search-contract', data)
    setLoading(true);
    axios.post('https://app.mobilassistance.com/website-service/api/app/search-contract', {
      mtTcNo: tcNo,
      mtPlate: plate
    })
      .then(response => {
        setgelendata(response.data.data);
        setLoading(false);
        setOpen(true) 
      })
      .catch(error => {
        setLoading(false);
        setLoadingError(false) 
      });
  };


  return (
    <div className="o-container o-container--page u-relative">
      <div className="c-bg-border">
        <div className="o-container">
          <div className="c-col__row">
            <div className="c-col-4 c-col"></div>
            <div className="c-col-4 c-col"></div>
            <div className="c-col-4 c-col"></div>
            <div className="c-col-4 c-col"></div>
          </div>
        </div>
      </div>
      <div className="o-container__wrapper">
        <div className="page-content">
        <div className="form-actions">
          <form id="sozlesmeara" >
            <div className="form-column__row">
              <div className="form-column">
                <div className="form-group">
                  <label htmlFor="nameSurname">TCK No</label>
                  <input type="text" onChange={handleTcChange} value={tcNo} id="frm_tckno" maxlengt="200" name="frm_tckno" placeholder="TCK No" />
                </div>
              </div>
              <div className="form-column">
                <div className="form-column">
                  <div className="form-group">
                    <label htmlFor="nameLastname">Plaka</label>
                    <input type="text" onChange={handlePlakaChange} value={plate} id="plaka" maxlengt="200" name="plaka" placeholder="Plaka" />
                  </div>
                </div>
              </div>
            </div>
            <div className="form-actions">
              <button className="c-button" onClick={SozlesmeAra} type='button'>Sözleşme Ara</button>
              {/* <a className="c-button" onClick={handleClick}>Sözleşme ara</a> */}
              {/* <button className="c-button" type="submit" onClick={handleSubmit}  >Sözleşme Ara</button> */}
            </div>
          </form>
          <br></br> <br></br> <br></br> <br></br> 
            {/* <a className="c-button" onClick={handleClick}>Sözleşme ara</a> */}
            {/* <button className="c-button" type="submit" onClick={handleSubmit}  >Sözleşme Ara</button> */}
            {loading && (
              <>Datalar YÜkleniyor Bekleyin</>
            )}
            {loadingError && (
              <>Ters giden birşeyler var formu gözden geçirin</>
            )}
            {isOpen && (
              <>
                {gelendata && gelendata.map(item => (
                  <>
                    <div key={item.dsPlate}>
                      <div className="c-dl__item">
                        <div className="left">
                          İsim Soyisim
                        </div>
                        <div className="right">
                          <div className="col">
                            <div className="title">{item.dsNameSurname}</div>
                          </div>
                        </div>
                      </div>
                      <div className="c-dl__item">
                        <div className="left">
                          Araç plaka
                        </div>
                        <div className="right">
                          <div className="col">
                            <div className="title">{item.dsPlate}</div>
                          </div>
                        </div>
                      </div>

                      <div className="c-dl__item">
                        <div className="left">
                          Araç Markası
                        </div>
                        <div className="right">
                          <div className="col">
                            <div className="title">{item.dsVehicleType}</div>
                          </div>
                        </div>
                      </div>
                      <div className="c-dl__item">
                        <div className="left">
                          Araç Türü
                        </div>
                        <div className="right">
                          <div className="col">
                            <div className="title">{item.dsVehicleUsedType}</div>
                          </div>
                        </div>
                      </div>


                      <div className="c-dl__item">
                        <div className="left">
                          Araç Tipi
                        </div>
                        <div className="right">
                          <div className="col">
                            <div className="title">{item.dsVehicleBrand}</div>
                          </div>
                        </div>
                      </div>
                      <div className="c-dl__item">
                        <div className="left">
                          Fatura Adresi
                        </div>
                        <div className="right">
                          <div className="col">
                            <div className="title">{item.dsInvoiceAddress}</div>
                          </div>
                        </div>
                      </div>
                      <div className="c-dl__item">
                        <div className="left">
                          Başlangıç Tarihi
                        </div>
                        <div className="right">
                          <div className="col">
                            <div className="title">{item.dtPackageStart}</div>
                          </div>
                        </div>
                      </div>
                      <div className="c-dl__item">
                        <div className="left">
                          Bitiş Tarihi
                        </div>
                        <div className="right">
                          <div className="col">
                            <div className="title">{item.dtPackageFinish}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br></br> <br></br>
                  </>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SozlesmeAra;