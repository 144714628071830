import markatescil from '../../../assets/img/kalitebelgeleri/marka_tescil.jpg'
import iso_27001 from '../../../assets/img/kalitebelgeleri/ISO_27001.jpg'
import iso_10002 from '../../../assets/img/kalitebelgeleri/ISO_10002_2018.jpg'
import iso_9001 from '../../../assets/img/kalitebelgeleri/ISO_9001_2015.jpg'
const Belgelerimiz = () => {
    return (
        <div className="o-container o-container--page u-relative">
            <div className="c-bg-border">
                <div className="o-container">
                    <div className="c-col__row">
                        <div className="c-col-4 c-col"></div>
                        <div className="c-col-4 c-col"></div>
                        <div className="c-col-4 c-col"></div>
                        <div className="c-col-4 c-col"></div>
                    </div>
                </div>
            </div>
            <div className="o-container__wrapper">
                <div className="c-tabs c-tabs--page">
                    <div className="c-tabs__content" >
                        <div className="c-tabs__tab-content" >
                            <div className="c-dl__item" >
                                <div className="left">
                                    <img alt="Mobil Assistance Marka Tescil" src={markatescil}  />
                                </div>
                                <div className="right">
                                    <div className="col">
                                        <div className="title">Mobil Assistance Marka Tescil</div>
                                    </div>
                                    <div className="col">
                                        <div className="actions">
                                            <a className="c-button" href={markatescil} target='_blank' rel="noopener noreferrer">  
                                                <span>İNCELE</span>
                                            </a> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="c-dl__item" >
                                <div className="left">
                                    <img alt="Mobil Assistance ISO 27001" src={iso_27001}  />
                                </div>
                                <div className="right">
                                    <div className="col">
                                        <div className="title">Mobil Assistance ISO 27001</div>
                                    </div>
                                    <div className="col">
                                        <div className="actions">
                                            <a className="c-button" href={iso_27001} target='_blank' rel="noopener noreferrer">  
                                                <span>İNCELE</span>
                                            </a> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="c-dl__item" >
                                <div className="left">
                                    <img alt="Mobil Assistance ISO 9001" src={iso_9001}  />
                                </div>
                                <div className="right">
                                    <div className="col">
                                        <div className="title">Mobil Assistance ISO 9001</div>
                                    </div>
                                    <div className="col">
                                        <div className="actions">
                                            <a className="c-button" href={iso_9001} target='_blank' rel="noopener noreferrer">  
                                                <span>İNCELE</span>
                                            </a> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="c-dl__item" >
                                <div className="left">
                                    <img alt="Mobil Assistance ISO 10002" src={iso_10002}  />
                                </div>
                                <div className="right">
                                    <div className="col">
                                        <div className="title">Mobil Assistance ISO 10002</div>
                                    </div>
                                    <div className="col">
                                        <div className="actions">
                                            <a className="c-button" href={iso_10002} target='_blank' rel="noopener noreferrer">  
                                                <span>İNCELE</span>
                                            </a> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Belgelerimiz;