import resim1 from '../assets/img/ikamearac/ikame_arac3.png'
import R01 from '../assets/img/ikamearac/01.png'
import R02 from '../assets/img/ikamearac/02.png'
import R03 from '../assets/img/ikamearac/03.png'
import R04 from '../assets/img/ikamearac/04.png'
import R05 from '../assets/img/ikamearac/05.png'

export default function IkameAracHizmeti() {
    return (
        <>
            <section className="c-brands u-relative" >
                <div className="c-brands__wrapper">
                    <div className="ikame_arac">
                        <div className="c-section-title">
                            <div className="c-section-title__sub-title" data-aos="fade-down" data-aos-delay="600">MOBİL ASSİSTANCE YOL YARDIM HİZMETLERİ</div>
                        </div>
                        <div className="c-know-us__item-container">
                            <div>
                                <div className='row'>
                                    <div className='col-lg-6 col-md-6 content-item'>
                                        <div data-aos="fade-right" data-aos-delay="900">
                                            <img src={R01} alt='Mobil Assistance'></img>
                                        </div>
                                        <br></br><br></br>
                                        <div data-aos="fade-right" data-aos-delay="900">
                                            <img src={R02} alt='Mobil Assistance'></img>
                                        </div>
                                        <div data-aos="fade-right" data-aos-delay="900">
                                            <img src={R03} alt='Mobil Assistance'></img>
                                        </div>
                                        <div data-aos="fade-right" data-aos-delay="900">
                                            <img src={R04} alt='Mobil Assistance'></img>
                                        </div>
                                        <div data-aos="fade-right" data-aos-delay="900">
                                            <img src={R05} alt='Mobil Assistance'></img>
                                        </div>
                                    </div>
                                    <div className='col-lg-6 col-md-6 content-item'>
                                        <br></br>
                                        <div data-aos="fade-left" data-aos-delay="900">
                                            <img src={resim1} width={480} alt='Mobil Assistance'></img>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="c-know-us__actions" >
                            <a className="c-special-button" href="/tr/hizmetlerimiz">DAHA FAZLASI İÇİN</a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
} 