import AOS from 'aos';
import 'aos/dist/aos.css';
import Slider from '../../component/slider2';
import Tanitim from '../../component/tanitim';
import Topbar from '../../component/topbar';
import Faaliyet from '../../component/faaliyet';
import Footer from '../../component/footer';
import WhyMobilassistance from '../../component/why';
import Online from '../../component/onlineislemler';
import IkameAracHizmeti from '../../component/ikamearac';
const MainPage = () => {
    AOS.init();
    return (
        <div className="App">
            <Topbar />
            <Slider />
            <Online />
            <Tanitim />
            <IkameAracHizmeti />
            <Faaliyet />
            <WhyMobilassistance />
            <Footer />
        </div>
    );
};

export default MainPage;