import 'bootstrap/dist/css/bootstrap.min.css';

import Svg_Hizli from '../assets/img/svg/ico_hizli_mobilassistance.svg'
import Svg_Seckin from '../assets/img/svg/ico_seckin_mobilassistance.svg'
import Svg_Teknolojik from '../assets/img/svg/ico_teknoloji_mobilassistance.svg'
import Svg_KolayErisim from '../assets/img/svg/ico_kolayersisim_mobilassistance.svg'
import Svg_Memnuniyet from '../assets/img/svg/ico_musteri_memnuniyet_mobilassistance.svg'
import Svg_Guven from '../assets/img/svg/ico_guven_mobilassistance.svg'




function WhyMobilassistance() {
    return (
        <>
            <section className="c-brands u-relative" >
                <div className="c-brands__wrapper">
                    <div className="about-lists">
                        <div className="section-title">
                            <h2>Neden Mobil Assistance</h2>
                        </div>
                        <div className="container">
                            <div className="row no-gutters">
                                <div className="col-lg-4 col-md-6 content-item" data-aos="fade-up" data-aos-delay="10">
                                    <span><img src={Svg_Hizli} alt='Hızlı ve Yüksek Standartta Hizmet' width={90}></img> </span>
                                    <h4>Hızlı ve Yüksek Standartta Hizmet</h4>
                                    <p>Dakikalar içerisinde müdahele ederek en kısa sürede çözümler üretiyoruz.</p>
                                </div>

                                <div className="col-lg-4 col-md-6 content-item" data-aos="fade-up" data-aos-delay="10">
                                    <span><img src={Svg_Guven} alt='Güven Veren Sektör Deneyimi' width={90}></img> </span>
                                    <h4>Güven Veren Sektör Deneyimi</h4>
                                    <p>Sektörde lider olan altyapımızla en uygun ve kaliteli hizmeti sunuyoruz. </p>
                                </div>

                                <div className="col-lg-4 col-md-6 content-item" data-aos="fade-up" data-aos-delay="10">
                                    <span><img src={Svg_Seckin} alt='Seçkin Tedarikçi Ağı' width={90}></img> </span>
                                    <h4> Seçkin Tedarikçi Ağı</h4><br></br>
                                    <p>Türkiye geneli seçkin tedarikçi ağımız ile her zaman yanınızdayız.</p>
                                </div>

                                <div className="col-lg-4 col-md-6 content-item" data-aos="fade-up" data-aos-delay="10">
                                    <span><img src={Svg_Memnuniyet} alt='100 % Müşteri Memnuniyeti' width={90}></img> </span>
                                    <h4>100 % Müşteri Memnuniyeti</h4>
                                    <p>81 ilde 100% Müşteri memnuniyeti.</p>
                                </div>

                                <div className="col-lg-4 col-md-6 content-item" data-aos="fade-up" data-aos-delay="10">
                                    <span><img src={Svg_KolayErisim} alt='Kolay Ulaşılabilir' width={90}></img> </span>
                                    <h4>Kolay Ulaşılabilir</h4>
                                    <p>7/24  Ulaşılabilir Çağrı merkezimizle her zaman yanınızdayız.</p>
                                </div>

                                <div className="col-lg-4 col-md-6 content-item" data-aos="fade-up" data-aos-delay="10">
                                    <span><img src={Svg_Teknolojik} alt='Güçlü ve Teknolojik Alt Yapı' width={90}></img> </span>
                                    <h4>Güçlü ve Teknolojik Alt Yapı</h4>
                                    <p>Kolay ve anında hizmet için sizde yerinizi şimdiden ayırtın.</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
export default WhyMobilassistance;