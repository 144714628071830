import '../../../App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

import Topbar from '../../../component/topbar';
import Faaliyet from '../../../component/faaliyet';
import Footer from '../../../component/footer';
import InlinePages from '../../../component/Pages/onlineislemler/sozlesmearama';


import Breadcrumb from "../../../component/application/breadcrums";
import PageContainer from '../../../component/application/pageContainer';

const BCrumb = [
    {
        to: '/',
        title: 'Anasayfa',
    },
    {
        to: "/tr/online-islemler",
        title: 'Online İşlemler',
    },
    {
        title: 'Sözleşme Ara',
    },
];

const Kurumsal = () => {
    AOS.init();
    return (
        <PageContainer title="Sözleşme Arama" description="Mobil assistance Sözleşme Arama Sayfası ">
            <Topbar />
            <Breadcrumb title="Mobil Assistance Sözleşme Ara" items={BCrumb} />
            <InlinePages />
            <Faaliyet />
            <Footer />
        </PageContainer>
    );
};
export default Kurumsal;
