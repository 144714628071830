import '../../../App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

import Topbar from '../../../component/topbar';
import Faaliyet from '../../../component/faaliyet';
import Footer from '../../../component/footer';
import KisiyeOzel from '../../../component/Pages/hizmetler/hizmet-kisiyeozel';


import Breadcrumb from "../../../component/application/breadcrums";
import PageContainer from '../../../component/application/pageContainer';

const BCrumb = [
    {
        to: '/',
        title: 'Anasayfa',
    },
    {
        to: '/tr/hizmetlerimiz',
        title: 'Hizmetler'
    },
    {
        title: 'Kişiye Özel Hizmetler',
    },
];

const YolYardimHizmetleri = () => {
    AOS.init();
    return (
        <PageContainer title="Kişiye Özel Hizmetler" description="Mobil assistance Kişiye Özel Hizmetler ">
            <Topbar />
            <Breadcrumb title="Mobil Assistance Kişiye Özel Hizmetler" items={BCrumb} />
            <KisiyeOzel />
            <Faaliyet />
            <Footer />
        </PageContainer>
    );
};
export default YolYardimHizmetleri;
