
import 'bootstrap/dist/css/bootstrap.min.css'; 
 
const PaketlerMotosiklet = () => {
    return (
        <>
        
         <div className="o-container o-container--page u-relative">
            <div className="o-container__wrapper">
                <div className="page-content">
                     Paket Motosiklet
                </div>
            </div>
        </div>
        </>
    );
};

export default PaketlerMotosiklet;