
const Kvkk = () => {
    return (
        <>
        <div className="mss">
            <h2>MOBIL ASSISTANCE SERVİS HİZMETLERİ DENETİM DANIŞMANLIK TİCARET LTD.ŞTİ'de Gizlilik</h2>
            <p>
                <p></p>Bu gizlilik ilkeleri Mobil Assistance'nin gizlilik konusundaki sorumluluklarının tespiti
                için hazırlanmıştır. Aşağıdaki maddeler www.mobilassistance.com web sitesi üzerindeki bilgi
                toplama ve dağıtımı işlemlerinin kurallarını içermektedir. IP adresinizi sunucularımızdaki
                sorunların giderilmesi ve internet sitemizi yönetmek için kullanacağız. IP adresiniz, sizi ve
                alışveriş sepetinizi tanımak ve açık demografik bilgilerinizin toplanması için kullanılacaktır.
                Mobil Assistance, kullanıcılarının kendi servisleri kanalı ile gönderdikleri, yayınladıkları
                bilgileri kontrol ve sansür etmemektedir. Kullanıcılar, Mobil Assistance'nin servisleri
                kullanımı esnasında gerçekleştirecekleri aktivitelerin yasal sonuçlarından dolayı kendileri
                sorumludurlar. Sitemizin müşteri veri tabanına kayıt sipariş bilgileri formunda
                kullanıcılarımız, iletişim bilgileri (isim, adres, telefon, e-posta adresi vb) gibi istenilen
                tüm bilgiler belirtmelidir. Bu formda aldığımız iletişim bilgilerini kullanıcılarımızın
                işlemlerinde, acil durumlarda, yol yardım talep ettiği durumlarda ve faturanızın teslimatında
                kullanmaktayız. Kullanıcılarımız isteklerine bağlı olarak sistemimizden kayıtlarını
                sildirebilirler. Alınan finansal bilgiler satın alınan ürün ve hizmetlerin bedelinin tahsil
                edilmesinde ve diğer gerek duyulan durumlarda kullanılacaktır. Kişiye özel bilgiler
                kullanıcılarımızın sisteme girişlerinde ve diğer gerektiği durumlarda kişinin kimliğinin
                doğrulanmasında kullanılacaktır. İstatistiki bilgiler ve profil bilgileri ayrıca sitemizin
                içinde de toplanmaktadır. Bu bilgileri istenilen tüm durumlarda kullanılabilir. Bu bilgiler
                ziyaretçi hareketlerinin izlenmesi, kişiye özel içerik sağlanması durumlarında kullanılacaktır.
                Sitemiz içerisinde başka sitelere de bağlantılar bulunmaktadır www.mobilassistance.com adlı web
                sitemiz diğer sitelerin gizlilik politikaları ve içeriklerinden sorumlu değildir.
                Mobil Assistance 'de Güvenlik Sitemizde bilgi kaybını, bilginin izin verilmeyen kullanımını ve
                izinsiz değiştirilmesini engellemek için firmamızca uygulanan güvenlik önlemleri bulunmaktadır.
                Bu güvenlik önlemleri şunlardır;
                1 - SSL Güvenlik Sertifikası ile kredi kartı tahsilatı sayesinde SecureSocketLayer ile şifreli
                olarak kredi kartı bilginiz bankamıza iletilir.
                2 - Kredi kartı numaranız kesinlikle kaydedilmez. İşlem yaptığınız anda kullanılan kart
                numaranızı biz de bilmeyiz. Sadece siz ve bankamız arasında olan bu işlem bizim sitemiz
                üzerinden olsa da işlem sırasında kredi kartınız sizin ile bankamız arasında bir sırdır.
                3 - Sitemiz dünya standardı olan Microsoft Windows Server teknolojisi ile çalışmaktadır. Bilinen
                en son korumalar yüklenmiş ve bir aksiliğe imkan vermemek için devamlı takip edilmektedir.
                Düzeltme / Yenileme Kullanıcılarımız sitemizde kayıtlı olan tüm bilgilerini dilediğinde
                değiştirme hakkına sahiptir. www.mobilassistance.com – Mobil Assistance
                <p></p>Gizlilik Sözleşmesi
                <p></p>GİZLİ BİLGİ, İşbu Sözleşme’nin imza tarihinden önce ya da sonra, sözleşmenin uygulanması
                amacıyla, her bir Tarafın kendisi ya da ilgili Taraf namına ilgili Taraf ’ın çalışanları
                tarafından diğer Tarafa ve/veya diğer Taraf ’ın çalışanlarına, yazılı ve /veya sözlü olarak ve
                /veya internet ortamında içerisinde ve/veya soft ortamda ifşa edilmiş olan ve/veya diğer Taraf,
                diğer Taraf ’ın çalışanları tarafından edinilmiş olan; ilgili Taraf’ın müşteri bilgileri,
                alışveriş bilgileri, satış bilgileri, hizmet bilgileri, ürün bilgileri, ödeme bilgileri, hesap
                bilgileri, banka bilgileri, finansal modeller, simülasyonlar, personel bilgileri, çalışma ve
                hizmet bilgileri, fiyatlandırma bilgileri, işletim yöntemleri, fikirler, buluşlar, know-howlar,
                markalar, logolar, patentler, yazılımlar, kaynak kodları, fikri ve sınai mülkiyet hakları,
                tasarım hakları, ticari sırlar , teknik prosesler, formüller, planlar, tasarılar, lisans ve
                izinler, çizimleri tertipler, modeller, projeksiyonlar, iş planları, pazar fırsatları, ilgili
                Taraf ya da onun namına üçüncü bir tarafça hazırlanmış raporlar veya veriler dahil, Tarafların
                birbirine her türlü kanalla açıkladıkları ve /veya bir Tarafın diğer Taraf hakkında edindiği TÜM
                BİLGİLER ;Tarafların arasındaki yazışmalar, görüşmeler yada toplantılar ve yazışmalar ve sözlü
                olarak mübadele edilen TÜM BİLGİLER; Her iki tarafça hazırlanmış tüm hizmet analizleri ,
                derleme, çalışma , teklif ve diğer belgeler; Tüm ticari anlaşmalar veya taraflar arasında
                akdedilen anlaşmalar, gizli bilgi alışverişini içeren sözleşmeler; ve fakat sayılanlarla sınırlı
                olmamak üzere her türlü bilgi ve /veya belgeyi ifade etmektedir. Taraflar, işbu sözleşmenin
                yapılmasına müteakip, gerekli olan tüm gizli bilgilerini tamamıyla serbest irade ve kabulleriyle
                birbirine beyan ve ifşa ettiklerini kayıtsız şartsız kabul etmişlerdir.
                <p></p><p></p>Her iki Taraf;
                Taraflardan her biri tüm bilgileri kesinlikle özel ve gizli tutmayı, herhangi bir sebeple
                kullanmamayı, Gizli Bilgiyi her ne sebeple olursa olsun, doğrudan ya da dolaylı olarak kendisi
                ya da herhangi bir 3. gerçek ve /veya tüzel kişi ve kuruluşlar için kullanmamayı, kullanılmasına
                izin vermemeyi, Gizli Bilgiyi, herhangi bir 3.gerçek ve /veya tüzel kişiye, firmaya, acentaya
                veya kuruma açıklamamayı, rapor etmemeyi, yayınlamamayı veya ifşa etmemeyi, bu hususta gerekli
                tüm tedbirleri almayı, Gizli Bilgilerin hiçbir bölümünü kopyalamamayı ya da çoğaltmamayı ya da
                başka bir 3.gerçek ve /veya tüzel kişi ve kuruluşlara aslını/çoğaltılmış hallerini devretmemeyi/
                vermemeyi , Gizli bilgileri 3. Gerçek ve/veya tüzel kişi ve kuruluşlara kısmen veya tamamen ifşa
                etmemeyi Gizli Bilgilerin gizlilik niteliğini ve bunları gizli tutulması gerekliliğini, Gizli
                Bilgileri sözleşme uyarınca bilmesi gereken çalışanlarına ve yöneticilerine bildirmeyi ve
                onların da taahhüdü altında çalışanlarını sağlamayı ve onların da iş bu gizlilik sözleşmesi
                gereğince doğrudan sorumlu olduklarını ve bu hususta kendilerini uyardığını, Diğer Tarafa ait
                Gizli Bilgilere en az kendi gizli bilgilerine ve fikri mülkiyet niteliği taşıyan bilgilerine
                uyguladığı güvenlik önlemleri ve özeni uygulamayı, GİZLİLİK VE KİŞİSEL VERİLERİN KORUNMASI
                HAKKINDA, Kişisel Verilerin Korunması Kanununda belirtilen tüm hususlara ve sorumluluklara tam
                ve eksiksiz olarak bağlı olacaklarını ve uyacaklarını, aksi takdirde oluşacak ve /veya
                oluşabilecek zararlardan ilgili kusurlu tarafın sorumlu olacağını, kayıtsız-şartsız gayri kabili
                rücu kabul taahhüt ederler.
                Firma, kendi bu gizli bilgelerini korumakta gösterdiği özenin aynısını Müşterinin gizli
                bilgilerini korumakta da göstermeyi kabul ve taahhüt ederler. Firma, bilginin gizliliği
                hususunda da işçilerini ve alt çalışanlarını uyarır.
                <p></p>Kişisel Verilerin Korunması Kanunu
                <p></p>1.Amaç ve talimatlar ile sınırlılık
                <p></p>Sözleşme kapsamında taraflar, süreçlere bağlı olarak ‘’veri sorumlusu’’ veya ‘’veri
                işleyen’’ konumunda olabileceklerdir. Taraflardan birinin, diğer taraf adına Kişisel Veri
                işlediği durumlarda ilgili taraf 6698 sayılı Kişisel Verilerin Korunması Kanunu
                (‘’Kanun’’)kapsamında ‘’veri işleyen ‘’olarak kabul edilecektir. Bu durumda, bahsi geçen taraf,
                Kişisel Verileri münhasıran karşı tarafın talimatları doğrultusunda işlemekte yükümlü olup,
                talimatların dışında ve /veya kendi adına herhangi bir şekilde veri işleme faaliyeti
                gerçekleştiremez. Veri sorumlusu ile veri işleyen ve/veya Kişisel Verileri üçüncü bir kişiye
                aktaran taraf, ilgili veri sahiplerinden Kanun kapsamında gerekli açık ve aydınlatılmış rızanın
                alındığını ve bu kapsamda ilgili veri sahiplerine Kanun ‘un 10. Maddesi kapsamında gerekli
                bilgilendirmelerin yapıldığı taahhüt eder. Kişisel Veriler, verilerin aktarıldığı tarafça
                münhasıran verilerin kendisine aktarılması amacı ile sınırlı olarak işlenebilir. Kişisel
                Verilerin anılan kapsamın dışına herhangi bir şekilde işlenmesi, verileri aktaran tarafın konuya
                ilişkin yazılı iznine tabidir. Kişisel Verilerin saklanması ve işlenmesi hususlarında üçüncü
                taraf hizmetlerinin kullanılması ve bu doğrultuda Kişisel Verilerin yurtiçi veya yurtdışına
                aktarımının söz konusu olması halinde, Kişisel Verilerin aktaran tarafın onayı doğrultusunda
                ilgili üçüncü taraflar Kanun ve yürürlükteki sair mevzuata uygunluğun sağlanması amacıyla
                gerekli sözleşmeler akdedilecektir. Sözleşme süresi içerisinde aktarılan Kişisel Veriler ilgili
                veri sahibi ile ayrıca bir yasal/akdi ilişki tesis edilmediği sürece, taraflar arasındaki hizmet
                ilişkisinin sona ermesi ile birlikte ilgili mevzuata uygun olarak silinecek veya yok
                edilecektir. Taraflar her birinin, Kişisel Verilerin saklanmasına ilişkin yasal bir
                zorunluluğunun bulunması halinde Kişisel Veriyi saklamakla yükümlü olan taraf, Kişisel Verileri
                ilgili mevzuat kapsamında gereken süre ve amaçla sınırlı olarak saklayabilir.
                <p></p>2.Veri güvenliği
                Taraflar, gerek kendi personeli gerekse üçüncü taraflarca Kişisel Verilere yetkisiz bir şekilde
                erişilmesini, işlenmesini ve Kişisel Verilerin kendisine aktarımı amacı dışında kullanılmasını
                engellemek üzere mevzuatta öngörülen önlemleri almakla yükümlüdür. Bu çerçevede, taraflardan her
                biri, Kanun, ilgili mevzuat ve Kişisel Verileri Koruma Kurulu tarafından öngörülen tüm
                yükümlülükleri tam ve eksiksiz olarak yerine getireceğini, aksi takdirde oluşacak ve/veya
                oluşabilecek tüm zararlardan sorumlu olacağını beyan, kabul ve taahhüt eder. Bu hususta
                doğabilecek her türlü uyuşmazlık taraflar arasında müzakere yoluyla çözülmeye çalışacak; bunun
                mümkün olamaması halinde, münhasıran ANKARA Mahkemeleri ve İcra Daireleri yetkili olacaktır.
                İşbu ek Sözleşme Türk hukukuna tabidir. Kişisel Verilerin, işbu Sözleşme’nin 1.1.hükmü uyarınca
                üçüncü bir tarafa aktarımının güvenli bir şekilde sağlanmasından sorumludur.
                <p></p>3.Mevzuat değişikliklerinin uygulanması
                Bahsi geçen düzenlemeler kapsamında meydana gelebilecek herhangi bir değişiklik veya güncelleme
                nedeniyle tarafların Kişisel Verilerin işlenmesine ilişkin süreçlerinde bir değişiklik
                yapılmasının gerekmesi halinde, ilgili taraf söz konusu değişikliği en geç ilgili yeni /güncel
                düzenleme yürürlüğe girmeden evvel tamamlamakla yükümlüdür. İlgili mevzuatta meydana gelebilecek
                değişikliklerin işbu Sözleşme’nin tadilini gerektirmesi halinde, taraflar söz konusu
                değişikliklerin en kısa sürede yapılabilmesi için makul çabayı gösterdiklerini kabul, beyan ve
                taahhüt ederler. Bununla birlikte, değişiklik yapılması gereken Sözleşme hükmü, taraflarca bu
                hususta ilgili mevzuatta uygun görülen azami süre içerisinde bir aksiyon alınmamış olsa dahi,
                yeni/güncel yasal düzenlemenin yürürlük tarihi itibariyle yeni /güncel mevzuata uygun şekilde
                uygulanacaktır.
                <p></p>MOBIL ASSISTANCE SERVİS HİZMETLERİ DENETİM DANIŞMANLIK TİCARET LTD.ŞTİ

                0850 255 02 03
                <p></p><p></p>info@mobilassistance.com
                www.mobilassistance.com
            </p>
            </div>
        </>
    );
};

export default Kvkk;