

import axios from "axios";
import Alert from 'react-bootstrap/Alert';
import { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { Formik } from "formik";
import * as Yup from 'yup';


const validationSchema = Yup.object({
    dsTitle: Yup.string().required("Zorunlu alan"),
    dsAuthorized: Yup.string().required("Zorunlu alan"),
    dsPhone1: Yup.string()
        .min(17, 'Telefon numarasnu kontrol edin')
        .max(17, 'Telefon numarasnu kontrol edin')
        .required("Zorunlu alan"),
    // districtId: Yup.string().required("Zorunlu alan"),
    dsAdress: Yup.string().required("Zorunlu alan"),
    dsMail: Yup.string().email('Geçersiz e-mail adresi').required('Zorunlu alan'),
    dsPassword: Yup.string()
        .min(6, 'Parolanız en az 6 karakter olmalıdır.')
        .required('Parola Gerekli'),
});


const TedarikciBasvurusu = () => {
    const [basvuruekrani, setBasvuruEkrani] = useState(true)
    const [sonuc, setSonuc] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loadingError, setLoadingError] = useState(false)

    const Kayit = (values) => {
        setLoading(true); 
        axios.post('https://app.mobilassistance.com/website-service/api/Supplier/new-supplier', {
            dsTitle: values.dsTitle,
            idSupplierType: values.idSupplierType,
            dsAuthorized: values.dsAuthorized,
            dsTaxNo: values.dsTaxNo,
            dsPhone1: values.dsPhone1,
            dsPhone2: values.dsPhone2,
            dsPhone3: values.dsPhone3,
            idCity: provinceId,
            idDistrict: districtId,
            dsAdress: values.dsAdress,
            dsMail: values.dsMail,
            dsPassword: values.dsPassword,
            dsPasswordAgain: values.dsPasswordAgain,
            dsDescription: values.dsDescription
        })
            .then(response => {
                // setgelendata(response.data.data);
                setBasvuruEkrani(false);
                setLoading(false);
                setSonuc(true)
            })
            .catch(error => {
                setLoading(false);
                setBasvuruEkrani(true)
                setLoadingError(true)
            });
    }
    const [provinces, setProvinces] = useState([]);
    const [district, setDistrict] = useState([]);
    const [provinceId, setProvinceId] = useState([]);
    const [districtId, setDistrictId] = useState([])

    const province = async () => {
        const response = await fetch('https://app.mobilassistance.com/website-service/api/app/get-city')
            .then(response => response.json());
        setProvinces(response.data);
    }
    useEffect(() => {
        province()
    }, [])

    const handleProvinceChange = async (event) => {
        setDistrict([]);
        setProvinceId(event.target.value)
        const responsedist = await fetch(`https://app.mobilassistance.com/website-service/api/app/get-district?idCity=${event.target.value}`)
            .then(responsedist => responsedist.json());
        setDistrict(responsedist.data);
    }
    const districtChangeHandler = (event) => {
        setDistrictId(event.target.value);
    }

    const Province = (props) => {
        return (
            <select onChange={props.handleProvinceChange} value={provinceId} name="province_id" className="form-group" required>
                <option value={0}>Lütfen Seçim Yapın</option>
                {provinces.map(province =>
                    <option value={province.idCity}
                        key={province.idCity}>{province.dsName}</option>
                )}
            </select>
        )
    }
    const District = (props) => {
        return (
            <select className="form-group" value={districtId} onChange={districtChangeHandler}>
                <option value={0}>Lütfen Seçim Yapın</option>
                {district.map(item =>
                    <option key={item.idDistrict} value={item.idDistrict}>{item.dsDistrict}</option>
                )}
            </select>
        )
    }

    function BasariliSonuc() {
        return (
            <>
                <Alert variant="success">
                    <Alert.Heading>Tedarikçi başvurunuz başarılı şekilde oluşturuldu</Alert.Heading>
                    <p>
                        Başvurunuz merkezimiz taradından işlem sırasına alınmıştır. En kısa süre içerisinde değerlendirilip tarafınıza dönüş
                        sağlanacaktır.
                    </p>
                    <hr />
                    <p className="mb-0">
                        İyi Günler dileriz
                    </p>
                </Alert>
            </>
        )
    }

    function HataliSonuc() {
        return (
            <>
                <Alert variant="danger">
                    <Alert.Heading>Tedarikçi başvurunuzda  bir hata oluştu</Alert.Heading>
                    <p>
                        Başvuru esnasında bir hata oluştu. Lütfen formu tekrardan gözden geçirin
                    </p>
                    <hr />
                    <p className="mb-0">
                        <button className="c-button" onClick={() => { setBasvuruEkrani(true); setLoadingError(false) }}>Forma Geri Dön</button>
                    </p>
                </Alert>
            </>
        )
    }
    return (
        <div className="o-container o-container--page u-relative">
            <div className="c-bg-border">
                <div className="o-container">
                    <div className="c-col__row">
                        <div className="c-col-4 c-col"></div>
                        <div className="c-col-4 c-col"></div>
                        <div className="c-col-4 c-col"></div>
                        <div className="c-col-4 c-col"></div>
                    </div>
                </div>
            </div>
            <div className="o-container__wrapper">
                <div className="page-content">
                    <div className="form-actions">
                        {basvuruekrani && (
                            <>
                                <div className="o-container__wrapper contact-ss" style={{ width: "60%", margin: "auto", }}>
                                    <div className="page-content">
                                        <Formik
                                            initialValues={{
                                                dsTitle: '',
                                                idSupplierType: '',
                                                dsAuthorized: '',
                                                dsTaxNo: '',
                                                dsPhone1: '',
                                                dsPhone2: '',
                                                dsPhone3: '',
                                                dsAdress: '',
                                                dsMail: '',
                                                dsPassword: '',
                                                dsPasswordAgain: '',
                                                dsDescription: ''
                                            }}
                                            validationSchema={validationSchema}
                                            onSubmit={(values, actions) => {
                                                setTimeout(() => {
                                                    // alert(JSON.stringify(values, null, 2)); 
                                                    Kayit(values);
                                                    actions.setSubmitting(false);
                                                }, 400);
                                            }}
                                        >
                                            {props => (
                                                <form id="BayiBasvuru" onSubmit={props.handleSubmit}>
                                                    <div className="form-column__row">
                                                        <div className="form-column">
                                                            <h2 className="page-content h1">TEDARİKÇİ BAŞVURU FORMU</h2>
                                                        </div>
                                                        <div>
                                                            Oto kurtarma, araç kiralama, oto lastik tamiri kapsamında tedarikçi ağımıza katılmak için başvuru yapabilirsiniz.
                                                        </div>
                                                        <br></br>
                                                    </div>
                                                    <div className="form-column__row">
                                                        <div className="form-column">
                                                            <div className="form-group">
                                                                <label htmlFor="nameLastname">Tedarikçi Türü</label>
                                                                <div className="c-col__row" style={{ margin: "auto", width: "80%", }}>
                                                                    <div className="c-col-4 c-col"><input type="radio" name="supplierType" onChange={props.handleChange('idSupplierType')} value="1" /> </div><b>Oto Kiralama</b>
                                                                    <div className="c-col-4 c-col"><input type="radio" name="supplierType" onChange={props.handleChange('idSupplierType')} value="2" /></div><b> Oto Kurtarma</b>
                                                                    <div className="c-col-4 c-col"><input type="radio" name="supplierType" onChange={props.handleChange('idSupplierType')} value="3" /> </div><b>Oto Lastikçi</b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-column__row">
                                                        <div className="form-column">
                                                            <div className="form-group">
                                                                <label htmlFor="nameLastname">Ticari Ünvan</label>
                                                                <input type="text" value={props.values.dsTitle} onChange={props.handleChange('dsTitle')} maxlengt="600" name="firmaAdi" placeholder="Ticari Ünvan" />
                                                                {props.errors.dsTitle && props.dsTitle}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-column__row">
                                                        <div className="row no-gutters">
                                                            <div className="col-lg-6 col-md-6 content-item" >
                                                                <div className="form-column">
                                                                    <div className="form-group">
                                                                        <label htmlFor="txtYetkili">Yetkili Ad Soyad</label>
                                                                        <input type="text" value={props.values.dsAuthorized} onChange={props.handleChange('dsAuthorized')} maxlengt="200" name="yetkili" placeholder="Yetkili" />
                                                                        {props.errors.dsAuthorized && props.errors.dsAuthorized}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-6 col-md-6 content-item" >
                                                                <div className="form-column">
                                                                    <div className="form-group">
                                                                        <label htmlFor="txt_VergiNo">Vergi No</label>
                                                                        <input type="text" id="plaka" maxlengt="200" name="txt_VergiNo" value={props.values.dsTaxNo} onChange={props.handleChange('dsTaxNo')} placeholder="Vergi Numarası" />
                                                                        {props.errors.dsTaxNo && props.errors.dsTaxNo}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-column__row">
                                                        <div className="row no-gutters">
                                                            <div className="col-lg-4 col-md-6 content-item" >
                                                                <div className="form-column">
                                                                    <div className="form-group">
                                                                        <label htmlFor="txt_Gsm">Gsm</label>
                                                                        <InputMask name='phone' placeholder='Telefon'
                                                                            className="mobile-phone"
                                                                            mask="0 (999) 999 99 999" maskChar={null} value={props.values.dsPhone1} onChange={props.handleChange('dsPhone1')} />
                                                                        {props.errors.dsPhone1 && props.errors.dsPhone1}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4 col-md-6 content-item" >
                                                                <div className="form-column">
                                                                    <div className="form-group">
                                                                        <label htmlFor="txttelefon2">Gsm2</label>
                                                                        <InputMask name='phone' placeholder='Telefon'
                                                                            className="mobile-phone"
                                                                            mask="0 (999) 999 99 999" maskChar={null} value={props.values.dsPhone2} onChange={props.handleChange('dsPhone2')} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6 content-item" >
                                                                <div className="form-column">
                                                                    <div className="form-group">
                                                                        <label htmlFor="txttelefon3">Telefon</label>
                                                                        <InputMask name='phone' placeholder='Telefon'
                                                                            className="mobile-phone"
                                                                            mask="0 (999) 999 99 999" maskChar={null} value={props.values.dsPhone3} onChange={props.handleChange('dsPhone3')} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-column__row">
                                                        <div className="form-column__row">
                                                            <div className="row no-gutters">
                                                                <div className="col-lg-12 col-md-6 content-item" >
                                                                    <div className="form-column">
                                                                        <div className="form-group">
                                                                            <label htmlFor="txt_Eposta">Eposta</label>
                                                                            <input type="text" value={props.values.dsMail} onChange={props.handleChange('dsMail')} maxlengt="200" name="eposta" placeholder="E posta" />
                                                                            {props.errors.dsMail && props.errors.dsMail}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="form-column__row">
                                                        <div className="row no-gutters">
                                                            <div className="col-lg-6 col-md-6 content-item" >
                                                                <div className="form-column">
                                                                    <div className="form-group">
                                                                        <label htmlFor="txtSehir">Şehir</label>
                                                                        <Province handleProvinceChange={handleProvinceChange} provinces={provinces} />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-6 col-md-6 content-item" >
                                                                <div className="form-column">
                                                                    <div className="form-group">
                                                                        <label htmlFor="txt_Ilce">İlçe</label>
                                                                        <District district={district} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-column__row">
                                                        <div className="form-column">
                                                            <div className="form-group">
                                                                <label htmlFor="txtAdres">Adres</label>
                                                                <input type="text" value={props.values.dsAdress} onChange={props.handleChange('dsAdress')} maxlengt="200" name="adres" placeholder="Adres" />
                                                                {props.errors.dsAdress && props.errors.dsAdress}
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="form-column__row">
                                                        <div className="form-column">
                                                            <div className="form-group">
                                                                <label htmlFor="txt_aciklama">Açıklama</label>
                                                                <textarea name="txt_aciklama" value={props.values.dsDescription} onChange={props.handleChange('dsDescription')} cols="40" rows="5" placeholder="Araç Sayısı araç özellikleri  alternatif bilgiler."></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-column__row">
                                                        <div className="row no-gutters">
                                                            <div className="col-lg-6 col-md-6 content-item" >
                                                                <div className="form-column">
                                                                    <div className="form-group">
                                                                        <label htmlFor="txt_parola">Şifre</label>
                                                                        <input type="password" value={props.values.dsPassword} onChange={props.handleChange('dsPassword')} maxlengt="200" name="parola" placeholder="Şifre" />
                                                                        {props.errors.dsPassword && props.errors.dsPassword}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-6 col-md-6 content-item" >
                                                                <div className="form-column">
                                                                    <div className="form-group">
                                                                        <label htmlFor="txt_parolaTekrar">Şifre Tekrarı</label>
                                                                        <input type="password" value={props.values.dsPasswordAgain} onChange={props.handleChange('dsPasswordAgain')} maxlengt="200" name="parolatekrar" placeholder="Şifre Tekrarı" />
                                                                        {props.errors.dsPasswordAgain && props.errors.dsPasswordAgain}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </div>
                                                    <div className="form-actions">
                                                        {/* <a className="c-button" href="#/" >Kayıt</a> */}
                                                        <button className="c-button" type="submit" >Kayıt Oluştur</button>
                                                    </div>
                                                </form>
                                            )}
                                        </Formik>
                                    </div>
                                </div>
                            </>
                        )}
                        {sonuc && (
                            <>
                                <BasariliSonuc />
                            </>
                        )}
                        {loading && (
                            <>Kayıt İşlemi Yapılıyor... Bekleyin</>
                        )}
                        {loadingError && (
                            <>
                                <HataliSonuc />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TedarikciBasvurusu;