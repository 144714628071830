
import 'bootstrap/dist/css/bootstrap.min.css';
import hakkinda from '../../../assets/img/svg/kurumsal/ico_svg_kurumsal_info.svg'
import hedefler from '../../../assets/img/svg/kurumsal/ico_svg_kurumsal_stairs.svg'
import kalite from '../../../assets/img/svg/kurumsal/ico_svg_kurumsal_quality.svg'
import tarihce from '../../../assets/img/svg/kurumsal/ico_svg_kurumsal_history.svg'
import kurumsal from '../../../assets/img/svg/kurumsal/ico_svg_kurumsal_identity.svg'
import belgeler from '../../../assets/img/svg/kurumsal/ico_svg_kurumsal_document.svg'
import film from '../../../assets/img/svg/kurumsal/ico_svg_kurumsal_movie.svg'
import etik from '../../../assets/img/svg/kurumsal/ico_svg_kurumsal_corporate.svg'



const KurumsalMain = () => {
    return (
        <div className="o-container o-container--page u-relative">
            <div className="o-container__wrapper">
                <div className="c-box c-box--list">
                    <a className="c-box__item c-box__item--list c-box__item--big" href="/tr/kurumsal/mobil-assistance-hakkinda">
                        <div className="c-box__img">
                        <img src={hakkinda} alt="Mobil Assistance Hakkında" width={100}/>
                        </div>
                        <div className="c-box__text">
                            <p>Mobil Assistance Hakkında</p>
                        </div>
                    </a>

                    <a className="c-box__item c-box__item--list c-box__item--big" href="/tr/kurumsal/kurumsal-hedefler">
                        <div className="c-box__img">
                            <img src={hedefler} alt="Hedeflerimiz" width={100}/>
                        </div>
                        <div className="c-box__text">
                            <p>Hedeflerimiz</p>
                        </div>
                    </a>
                    <a className="c-box__item c-box__item--list c-box__item--big" href="/tr/kurumsal/tr/kurumsal/kalite-politikamiz">
                        <div className="c-box__img">
                        <img src={kalite} alt="Kalite Politikamız" width={100}/>
                        </div>
                        <div className="c-box__text">
                            <p>Kalite Politikamız</p>
                        </div>
                    </a> 
                    <a className="c-box__item c-box__item--list c-box__item--big" href="/tr/kurumsal/tarihcemiz">
                        <div className="c-box__img">
                        <img src={tarihce} alt="Tarihçemiz" width={100}/>
                        </div>
                        <div className="c-box__text">
                            <p>Tarihçemiz</p>
                        </div>
                    </a> 
                    <a className="c-box__item c-box__item--list c-box__item--big" href="/tr/kurumsal/etik-kurallarimiz">
                        <div className="c-box__img">
                        <img src={etik} alt="Etik Kurallarımız" width={100}/>
                        </div>
                        <div className="c-box__text">
                            <p>Etik Kurallarımız</p>
                        </div>
                    </a> 
                    <a className="c-box__item c-box__item--list c-box__item--big" href="/tr/kurumsal/kurumsal-kimlik">
                        <div className="c-box__img">
                        <img src={kurumsal} alt="Kurumsal Kimlik" width={100}/>
                        </div>
                        <div className="c-box__text">
                            <p>Kurumsal Kimlik</p>
                        </div>
                    </a> 
                    <a className="c-box__item c-box__item--list c-box__item--big" href="/tr/kurumsal/tanitim-filmi">
                        <div className="c-box__img">
                        <img src={film} alt="Tanırım Filmi" width={100}/>
                        </div>
                        <div className="c-box__text">
                            <p>Tanıtım Filmi</p>
                        </div>
                    </a> 
                    <a className="c-box__item c-box__item--list c-box__item--big" href="/tr/kurumsal/kalite-belgelerimiz">
                        <div className="c-box__img">
                        <img src={belgeler} alt="Kalite Belgelerimiz" width={100}/>
                        </div>
                        <div className="c-box__text">
                            <p>Kalite Belgelerimiz</p>
                        </div>
                    </a> 
                </div>
            </div> 
        </div>
    );
};

export default KurumsalMain;