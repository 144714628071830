
import IkameArac from '../../../assets/img/hizmetler/hizmetler_ikamearac.jpg'

const HizmetlerIkameArac = () => {
    return (
        <>
            <div className="o-container o-container--page u-relative">
                <div className="o-container__wrapper">
                    <div className="page-content">
                        <>
                            <img alt="Mobil Assistance Yol Yardım" className="align-right" src={IkameArac} />
                            <p>
                                Yoldaki Beklenmedik Durumlarda Yanınızdayız: İkame Araç Hizmeti
                            </p>
                            <p>
                                Seyahat ederken veya günlük işlerinizi hallederken, aracınızın beklenmedik şekilde kaza yapması can sıkıcı olabilir.
                            </p>
                            <p>
                                Bu gibi durumlarda sizlere gereken en önemli şey hızlı ve güvenilir ikame araç hizmetidir.
                            </p>
                            <p>
                                Mobil Assistance Yol Yardım Hizmetleri, yolda kalan sürücülere profesyonel ikame araç hizmetleri sunan lider bir firma olarak faaliyet göstermektedir.
                            </p>
                            <p>
                                Deneyimli ekimizle 7/24 hizmet vererek acil durumlara anında müdahale ediyoruz.
                            </p>
                            <p>
                                Müşterilerimize araçlarının çift taraflı kazaya karışması halinde 7 gün B segment ikame desteği sağlıyoruz.
                            </p>
                            <p>
                                İkame araç desteğine ek olarak; 2 Arza/2 Kaza desteği mevcuttur,
                            </p>
                            <p>
                                Çağrı merkezi numaramız günün her saati açık tutulmaktadır ve talebinize anında yanıt verilmektedir.
                            </p>
                            <p>
                                Sizi yolda bırakmamak adına iyi bir çalışma ağına sahibiz; böylece Türkiye'nin neresinde olursanız olun size en yakın ekibi gönderebilir ve ihtiyacınıza uygun ikame aracı temin edebiliriz.
                            </p>
                            <p>
                                Yoldaki beklenmedik durumlarda size yardım eli uzatan profesyonel bir ekibe ihtiyacınız varsa tek yapmanız gereken bizimle iletişime geçmek!
                            </p>
                        </>
                    </div>
                </div >
            </div >
        </>
    );
};

export default HizmetlerIkameArac;