import axios from "axios";
import { useEffect, useState } from "react";
  
const baseURL = "https://app.mobilinfo.net/website-service/api/app/get-city";
const YolYardimSeo = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
        axios.get(baseURL)
            .then(response => {
                setData(response.data.data);
            })
            .catch(error => {
                console.error(error);
            });
    }, []); 
    return (
        <>
            <div className="o-container o-container--page u-relative">
                <div className="c-bg-border">
                    <div className="o-container">
                        <div className="c-col__row">
                            <div className="c-col-4 c-col"></div>
                            <div className="c-col-4 c-col"></div>
                            <div className="c-col-4 c-col"></div>
                            <div className="c-col-4 c-col"></div>
                        </div>
                    </div>
                </div>
                <div className="o-container__wrapper">
                    <div className="page-content">
                        {data ? (
                            <ul>
                                {data.map(item => (
                                    <li key={item.dsName}>{item.dsName}   detay <a href={`/tr/mobil-assistance/yol-yardim?sehir=${item.dsName}?plaka=${item.mtPlate}`} >Git</a></li>
                                ))}
                            </ul>
                        ) : (
                            <p>Loading...</p>
                        )}

                        <p>İnsan Kaynakları</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default YolYardimSeo;