const hakkimizda = () => {
    return (
        <>
            <div className="o-container o-container--page u-relative">
                <div className="c-bg-border">
                    <div className="o-container">
                        <div className="c-col__row">
                            <div className="c-col-4 c-col"></div>
                            <div className="c-col-4 c-col"></div>
                            <div className="c-col-4 c-col"></div>
                            <div className="c-col-4 c-col"></div>
                        </div>
                    </div>
                </div>
                <div className="o-container__wrapper">
                    <div className="c-history">
                        <div className="c-history__list">
                            <div className="c-history__item" >
                                <div className="c-history__date" data-aos="fade-up" data-aos-delay="600">2024</div>
                                {/* <div className="c-history__container" data-aos="fade-up" data-aos-delay="600">
                                    <div className="c-history__content">
                                        <div className="image--container">
                                            <div className="image"><img alt="" src="/assets/img/sample.jpg" /></div>
                                        </div>
                                        <div className="desc">
                                            <b>Mobil Assistance </b>
                                            İle iş ortaklığı yapan acentelerimiz, Kefalet sigortası ile
                                            ticari risklere karşı koruma altına aldık.
                                        </div>
                                    </div>
                                </div> */}
                                <div className="c-history__container c-history__container--reverse" data-aos="fade-up" data-aos-delay="600">
                                    <div className="c-history__content">
                                        <div className="image--container">
                                        </div>
                                        <div className="desc">
                                        Mobil Assistance Yol Yardım Hizmetleri olarak yol yardım/araç kurtarma hizmeti vermiş olduğumuz tüm araçları taşıyıcı sorumluluk sigortası ile güvence altına aldık.  
                                        </div>
                                    </div>
                                </div>
                                <div className="c-history__container" data-aos="fade-up" data-aos-delay="600">
                                    <div className="c-history__content">
                                        <div className="image--container">
                                        </div>
                                        <div className="desc">
                                        İlk tatil kampanyamızı düzenleyip acentelerimizi Kıbrıs'da misafir ettik.
                                        </div>
                                    </div>
                                </div>
                                <div className="c-history__container c-history__container--reverse" data-aos="fade-up" data-aos-delay="600">
                                    <div className="c-history__content">
                                        <div className="image--container">
                                            {/* <!--<div className="image"><img alt="" src="/assets/img/sample.jpg" /></div>--> */}
                                        </div>
                                        <div className="desc">Ağır ticari araç gruplarında hizmet vermeye başladık.</div>
                                    </div>
                                </div>
                                <div className="c-history__container" data-aos="fade-up" data-aos-delay="600">
                                    <div className="c-history__content">
                                        <div className="image--container">
                                            {/* <!--<div className="image"><img alt="" src="/assets/img/sample.jpg" /></div>--> */}
                                        </div>
                                        <div className="desc">
                                        Çağrı merkezi kapasitemizi artırarak günlük karşılanabilir çağrı kapasitemizi 1000 çağrı üzerine çıkardık.
                                    </div>
                                    </div>
                                </div>
                            </div>

                            <div className="c-history__item" >
                                <div className="c-history__date" data-aos="fade-up" data-aos-delay="600">2023</div>
                                <div className="c-history__container">
                                    <div className="c-history__content" data-aos="fade-up" data-aos-delay="600">
                                        <div className="image--container">
                                            <div className="image"><img alt="" src="/assets/img/sample.jpg" /></div>
                                        </div>
                                        <div className="desc">
                                            Luna Medya<b> BAŞARI VE KARİYER ÖDÜLLERİ </b> Kapsamında <b> YILIN LİDER ASSİSTANCE FİRMASI </b>
                                            Ödülünü almaya hak kazandık.
                                        </div>
                                    </div>
                                </div>
                                <div className="c-history__container c-history__container--reverse" data-aos="fade-up" data-aos-delay="600">
                                    <div className="c-history__content">
                                        <div className="image--container">
                                        </div>
                                        <div className="desc"><b>Mobil Assistance Yol Yardım Hizmetleri </b>
                                         olarak inşaat firması ile 3 ayrı projede inşaat başlatarak Mobil Assistance Yol Yardım Hizmetleri sermayesine dahil ettik.
                                            </div>
                                    </div>
                                </div>
                                <div className="c-history__container" data-aos="fade-up" data-aos-delay="600">
                                    <div className="c-history__content">
                                        <div className="image--container">
                                        </div>
                                        <div className="desc">Sermaye artırımı yaparak sektörde sigorta acentelerine hizmet veren
                                            <b> EN YÜKSEK SERMAYEYE SAHİP ASSİSTANCE FİRMA </b> olduk.</div>
                                    </div>
                                </div>
                            </div>

                            <div className="c-history__item" >
                                <div className="c-history__date" data-aos="fade-up" data-aos-delay="600">2022</div>
                                <div className="c-history__container" data-aos="fade-up" data-aos-delay="600">
                                    <div className="c-history__content">
                                        <div className="image--container">
                                            <div className="image"><img alt="" src="/assets/img/sample.jpg" /></div>
                                        </div>
                                        <div className="desc">
                                            <b>MOBİL ASSİSTANCE BİNEK ARAÇ FİLOSU </b>
                                            'nu kurduk ikame araç taleplerini kendi bünyemizden karşılamaya başladık.
                                        </div>
                                    </div>
                                </div>
                                <div className="c-history__container c-history__container--reverse" data-aos="fade-up" data-aos-delay="600">
                                    <div className="c-history__content">
                                        <div className="image--container">
                                        </div>
                                        <div className="desc">300 metrekare  olan <b> çağrı merkezi </b> mizi kurduk.</div>
                                    </div>
                                </div>
                            </div>

                            <div className="c-history__item" >
                                <div className="c-history__date" data-aos="fade-up" data-aos-delay="600">2021</div>
                                <div className="c-history__container" data-aos="fade-up" data-aos-delay="600">
                                    <div className="c-history__content">
                                        <div className="image--container">
                                            <div className="image"><img alt="" src="/assets/img/sample.jpg" /></div>
                                        </div>
                                        <div className="desc">
                                            <b>Mobil Assistance Yol Yardım Hizmetleri  </b > Bayilik sistemini kurduk ve ilk sözleşmemizi ürettik.
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="c-history__item" >
                                <div className="c-history__date" data-aos="fade-up" data-aos-delay="600">2019</div>
                                <div className="c-history__container">
                                    <div className="c-history__content" data-aos="fade-up" data-aos-delay="600">
                                        <div className="image--container">
                                            <div className="image"><img alt="" src="/assets/img/sample.jpg" /></div>
                                        </div>
                                        <div className="desc">
                                            <b>Mobil Assistance Yol Yardım Hizmetleri </b>
                                            Firma Kuruluşu Fizibilite çalışmaları tedarik zincirini oluşturduk.
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default hakkimizda;