import test from '../../../assets/img/kurumsal/kurumsal_kalitepolitikasi.jpg'
const hakkimizda = () => {
    return (
        <div className="o-container o-container--page u-relative">
            <div className="c-bg-border">
                <div className="o-container">
                    <div className="c-col__row">
                        <div className="c-col-4 c-col"></div>
                        <div className="c-col-4 c-col"></div>
                        <div className="c-col-4 c-col"></div>
                        <div className="c-col-4 c-col"></div>
                    </div>
                </div>
            </div>
            <div className="o-container__wrapper">
                <div className="page-content">
                    <img alt="Mobişl Assistance Kurumsal Değerlerimiz" className="align-right" src={test} />
                    <p>
                        Mobil Assistance Yol Yardım Hizmetleri Olarak Kalite politikamız, kurumumuzdan hizmet alan bütün müşterilerimizin memnuniyetini en üst düzeye çıkarmak amacıyla düzenlenmiş olup, aşağıdaki noktaları içermektedir:
                    </p>
                    <p>
                        Mevcut kaynakları etkin ve verimli kullanarak, müşterilerimizin ihtiyaç ve beklentilerini süresinde karşılamak.
                    </p>
                    <p>
                        ISO 9001:2015 Kalite Yönetim Sistemi Standartları, uygulanabilir tüm şartlar ile güncel yasal mevzuatlar çerçevesinde hizmetlerimizi sürekli geliştirmek.
                    </p>
                    <p>
                        ISO 10002:2018 Müşteri Memnuniyet Yönetim Sistemi tüm şartlar ile güncel yasal mevzuatlar çerçevesinde hizmetlerimizi sürekli geliştirmek.
                    </p>
                    <p>
                        ISO/IEC 27001:2013 Bilgi Güvenliği Yönetim Sistemleri Standartları, uygulanabilir tüm şartlar ile güncel yasal mevzuatlar çerçevesinde hizmetlerimizi sürekli geliştirmek.
                    </p>
                    <p>
                        Yönetim sistemimizin etkinliğini sürekli takip ederek, gerekli görülen iyileştirmeleri gerçekleştirerek uygulanabilir şartları oluşturmak ve yürütmek.
                    </p>
                    <p>
                        Personelimizin düzenli olarak eğitimini sağlamak, teknolojik yenilikleri yakından takip etmek, bu sayede sunulan hizmetlerdeki kaliteyi artırmak ve kalite konusundaki sürekliliği sağlamak.
                    </p>
                    <p>
                        Müşteri geri bildirimlerini etkin bir şekilde yönetmek, fark oluşturan ve değer yaratan işlemleri güçlendirmek, değer yaratmayan işlemleri revize etmek veya yürürlükten kaldırmak.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default hakkimizda;