import '../../../App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

import Topbar from '../../../component/topbar';
import Faaliyet from '../../../component/faaliyet';
import Footer from '../../../component/footer';
import InlinePages from '../../../component/Pages/hizmetler/hizmetler';


import Breadcrumb from "../../../component/application/breadcrums";
import PageContainer from '../../../component/application/pageContainer';

const BCrumb = [
    {
        to: '/',
        title: 'Anasayfa',
    },
    {
        to: "/tr/hizmetlerimiz",
        title: 'Hizmetlerimiz',
    }, 
];

const Hizmetler = () => {
    AOS.init();
    return (
        <PageContainer title="Hizmetlerimiz" description="Mobil Assistance Hizmetlerimiz Sayfası ">
            <Topbar />
            <Breadcrumb title="Mobil Assistance Hizmetlerimiz" items={BCrumb} />
            <InlinePages />
            <Faaliyet />
            <Footer />
        </PageContainer>
    );
};
export default Hizmetler;
