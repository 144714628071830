import '../../../App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

import Topbar from '../../../component/topbar';
import Footer from '../../../component/footer';
import InlinePages from '../../../component/Pages/paketler/paketikamearac';


import Breadcrumb from "../../../component/application/breadcrums";
import PageContainer from '../../../component/application/pageContainer';
 
const BCrumb = [
    {
        to: '/',
        title: 'Anasayfa',
    },
    {
        to: "/tr/paketler",
        title: 'Yol Yardım Paketleri',
    }, 
    {
        to: "/tr/paketler-ikame-arac-yardim-paketleri",
        title: 'İkame Araç Yol Yardım Paketleri',
    }, 
];

const PaketIkame = () => {
    AOS.init();
    return (
        <PageContainer title="İkame Araç Yol Yardım Paketleri" description="Mobil assistance İkame Araç Yol Yardım Paketleri Sayfası ">
            <Topbar />
            <Breadcrumb title="İkame Araç Yol Yardım Paketleri" items={BCrumb} />
            <InlinePages /> 
            <Footer />
        </PageContainer>
    );
};
export default PaketIkame;
