import '../../../App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

import Topbar from '../../../component/topbar';
import Faaliyet from '../../../component/faaliyet';
import Footer from '../../../component/footer';
import InlinePages from '../../../component/Pages/paketler/paketler';


import Breadcrumb from "../../../component/application/breadcrums";
import PageContainer from '../../../component/application/pageContainer';

const BCrumb = [
    {
        to: '/',
        title: 'Anasayfa',
    },
    {
        to: "/tr/paketler",
        title: 'Yol Yardım Paketleri',
    }, 
];

const Kurumsal = () => {
    AOS.init();
    return (
        <PageContainer title="Yol Yardım Paketleri" description="Mobil assistance Yol Yardım Paketleri Sayfası ">
            <Topbar />
            <Breadcrumb title="Mobil Assistance Yol Yardım Paketleri" items={BCrumb} />
            <InlinePages />
            <Faaliyet />
            <Footer />
        </PageContainer>
    );
};
export default Kurumsal;
