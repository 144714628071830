import React, { useState, useEffect } from 'react';
import CountUp from 'react-countup';
import svg_mutlumusteri from '../assets/img/svg/svg_mutlumusteri.svg'
import svg_hizmet from '../assets/img/svg/svg_hizmet.svg'
import svg_hizmetnoktasi from '../assets/img/svg/svg_hizmetnoktasi.svg'
import svg_harita from '../assets/img/svg/svg_harita.svg'

const baseURL = "https://app.mobilassistance.com/website-service/api/app/get-live-here";



export default function Tanitim() {

    const [data, setData] = useState(null);

    useEffect(() => {
        async function veriGoster() {
            fetch(baseURL)
                .then(response => response.json())
                .then(json => setData(json))
                .catch(error => console.error(error));
        }
        veriGoster()
    }, []);

    return (
        <>
            <section id="c-know-us" className="c-know-us u-relative">
                <div className="c-know-us__wrapper">
                    <div className="o-container">
                        <div className="c-section-title">
                            <div className="c-section-title__sub-title" data-aos="fade-right" data-aos-delay="600">RAKAMLARLA MOBİL ASSİSTANCE </div>
                            {/* <div className="c-section-title__main">
                                <h1 className="c-section-title__main--title" data-aos="fade-right" data-aos-delay="600">Canlı Canlı Buradayız</h1>
                                <p className="c-section-title__main--desc" data-aos="fade-left" data-aos-delay="600">
                                Mobil Assistance, güven, özen ve empati temelinde şekillenen güçlü teknoloji ve iyi düşünülmüş hizmetleriyle sektörde öncü bir asistans destek hizmeti sunmaktadır.</p>
                            </div> */}
                        </div>
                        <br></br> <br></br>
                        <div className="c-know-us__item-container">
                            {data ? <pre>
                                <div className="c-col__row">
                                    <div className="c-col c-col-4" data-aos="fade-up" data-aos-delay="400">
                                        <div className="c-know-us__item">
                                            <div className="c-icon-box">
                                                <div className="c-icon-box__icon">
                                                    <h1><CountUp separator='.' start={1} end={JSON.stringify(data.data.sales, null, 2)} /></h1>
                                                </div>
                                                <div className="c-icon-box__title">
                                                    <span><img src={svg_mutlumusteri} alt='Mobil Assistance Mutlu Müşteri' width={90}></img></span>
                                                </div>
                                                <div className="c-icon-box__desc">Mutlu Müşteri</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="c-col c-col-4" data-aos="fade-up" data-aos-delay="400">
                                        <div className="c-know-us__item">
                                            <div className="c-icon-box">
                                                <div className="c-icon-box__icon">
                                                    <h1><CountUp separator='.' start={1} end={JSON.stringify(data.data.service, null, 2)} /></h1>
                                                </div>
                                                <div className="c-icon-box__title">
                                                    <span><img src={svg_hizmet} alt='Mobil Assistance Mutlu Müşteri' width={90}></img></span>
                                                </div>
                                                <div className="c-icon-box__desc">Hizmet</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="c-col c-col-4" data-aos="fade-up" data-aos-delay="400">
                                        <div className="c-know-us__item">
                                            <div className="c-icon-box">
                                                <div className="c-icon-box__icon">
                                                    <h1><CountUp separator='.' start={1} end={JSON.stringify(data.data.supplier, null, 2)} /></h1>
                                                </div>
                                                <div className="c-icon-box__title">
                                                    <span><img src={svg_hizmetnoktasi} alt='Mobil Assistance Mutlu Müşteri' width={90}></img></span>
                                                </div>
                                                <div className="c-icon-box__desc">Hizmet Noktası</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="c-col c-col-4" data-aos="fade-up" data-aos-delay="400">
                                        <div className="c-know-us__item">
                                            <div className="c-icon-box">
                                                <div className="c-icon-box__icon">
                                                    <h1><CountUp separator='.' start={1} end={JSON.stringify(data.data.city, null, 2)} /></h1>
                                                </div>
                                                <div className="c-icon-box__title">
                                                    <span><img src={svg_harita} alt='Mobil Assistance Mutlu Müşteri' width={90}></img></span>
                                                </div>
                                                <div className="c-icon-box__desc">İlde</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </pre> : 'Yükleniyor...'}
                        </div>
                        <div className="c-know-us__actions" data-aos="fade-up" data-aos-delay="1200">
                            <a className="c-special-button" href="/tr/kurumsal/mobil-assistance-kurumsal">BİZİ TANIYIN</a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
} 