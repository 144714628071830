import '../../../App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

import Topbar from '../../../component/topbar';
import Faaliyet from '../../../component/faaliyet';
import Footer from '../../../component/footer';
import Etik from '../../../component/Pages/kurumsal/etik';


import Breadcrumb from "../../../component/application/breadcrums";
import PageContainer from '../../../component/application/pageContainer';

const BCrumb = [
    {
        to: '/',
        title: 'Anasayfa',
    },
    {
        to: '/tr/kurumsal/mobil-assistance-kurumsal',
        title: 'Kurumsal'
    },
    {
        title: 'Etik Kurallarımız',
    },
];

const Belgeler = () => {
    AOS.init();
    return (
        <PageContainer title="Etik Kurallarımız" description="Mobil assistance Etik Kurallarımız ">
            <Topbar />
            <Breadcrumb title="Mobil Assistance Etik Kurallarımız" items={BCrumb} />
            <Etik />
            <Faaliyet />
            <Footer />
        </PageContainer>
    );
};
export default Belgeler;
