import '../../App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

import Topbar from '../../component/topbar';
import Faaliyet from '../../component/faaliyet';
import Footer from '../../component/footer';
import YolYardim from '../../component/Pages/other/yolyardim';


import Breadcrumb from "../../component/application/breadcrums";
import PageContainer from '../../component/application/pageContainer';

const BCrumb = [
    {
        to: '/',
        title: 'Anasayfa',
    },
    {
        title: 'İletişim',
    },
    {
        title: 'Mobil Assistance Yol Yardım Hizmetleriı',
    },
];

const YolYardimSehir = () => {
    AOS.init();
    return (
        <PageContainer title="Yol Yardım Hizmetleri" description="Mobil Assistance Yol Yardım Hizmetleri ">
            <Topbar />
            <Breadcrumb title="Mobil Assistance Yol Yardım Hizmetleri" items={BCrumb} />
            <YolYardim />
            <Faaliyet />
            <Footer />
        </PageContainer>
    );
};
export default YolYardimSehir;
