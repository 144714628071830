import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import Modal from 'react-bootstrap/Modal';
 
import s_otomobil from '../assets/img/services/services_otomobil.png'
import s_hafifticari from '../assets/img/services/services_hafifticari.png'
import s_agirticari from '../assets/img/services/services_agirticari.png'
import s_motosiklet from '../assets/img/services/services_motosiklet.png'
import s_ikamearac from '../assets/img/services/services_ikamearac.png'
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import Sozlesme from './application/sozlemeicerik';


export default function Component() {
    const [modalShow, setModalShow] = useState(false);
    function ModalPackage(props) {
        return (
          <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={modalShow}
           >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
               {props.Package} Paket Satış Sözleşmesi
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Sozlesme Package={props.Package} />
            </Modal.Body>
            <Modal.Footer>
              <Button className='c-special-button' onClick={() => setModalShow(false)}>Paket Satınal</Button>
            </Modal.Footer>
          </Modal>
        );
      }
    return (
        <>
            <section className="c-services u-relative"> 
                <div className="c-services__wrapper">
                    <div className="o-container">
                        <div className="c-section-title">
                            <div className="c-section-title__sub-title">YOL YARDIM HİZMET PAKETLERİMİZ </div>
                            <div className="c-section-title__main">
                                {/* <h2 className="c-section-title__main--title" data-aos="fade-left" data-aos-delay="600">Ülkemize değer katmak için varız...</h2> */}
                            </div>
                        </div>
                        <div className="c-services__item--wrapper" data-aos="fade-up" data-aos-delay="600">
                            <div className="c-services__item-container">
                                <div className="c-col__row c-services__items">
                                    <Swiper
                                        slidesPerView={5}
                                        spaceBetween={4}
                                        pagination={{
                                            type: 'fraction',
                                        }}
                                        navigation={true}
                                        modules={[Navigation]}
                                        className="mySwiper"
                                    >
                                        <SwiperSlide>
                                            <div className="c-services__item" >
                                                <div className="c-services__item--title" > 
                                                        <span >
                                                            Motosiklet
                                                            <div className="c-services__item--btn" >
                                                            {modalShow && (
                                                                <>
                                                                    <ModalPackage Package="Motosiklet" />
                                                                </>
                                                                )}
                                                                <a className="c-button" style={{ position: 'absolute', height: 30, fontSize: 10 }} href={() => false}>SÖZLEŞME İÇERİĞİ </a>
                                                                {/* onClick={() => setModalSöhow(true)} */}
                                                            </div>
                                                        </span>
                                                </div>
                                                <div className="c-services__item--image" >
                                                    <div className="c-services__item--image-container" >
                                                        <img alt="Motosiklet Paketleri" src={s_motosiklet} />
                                                    </div>
                                                </div> 
                                            </div>
                                            {/* <div className="c-button_actions" data-aos="fade-up" data-aos-delay="1200">
                                                <a className="c-special-button" href="/tr/online-islemler/sozlesme-ara">SATINAL</a>
                                            </div> */}
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="c-services__item" >
                                                <div className="c-services__item--title" >
                                                        <span >
                                                            Otomobil
                                                            <div className="c-services__item--btn" >
                                                            {/* {modalShow && (
                                                                <>
                                                                    <ModalPackage Package="Otomobil" />
                                                                </>
                                                                )}
                                                                <a className="c-button" style={{ position: 'absolute', height: 30, fontSize: 10 }} href={() => false} onClick={() => setModalShow(true)}>SÖZLEŞME İÇERİĞİ </a> */}
                                                            </div>
                                                        </span>

                                                </div>
                                                <div className="c-services__item--image" >
                                                    <div className="c-services__item--image-container" >
                                                        <img alt="Otomobil" src={s_otomobil} />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="c-button_actions" data-aos="fade-up" data-aos-delay="1200">
                                                <a className="c-special-button" href="/tr/online-islemler/sozlesme-ara">SATINAL</a>
                                            </div> */}
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="c-services__item" >
                                                <div className="c-services__item--title" >
                                                        <span >
                                                            Hafif Ticari
                                                            <div className="c-services__item--btn" >
                                                            {/* {modalShow && (
                                                                <>
                                                                    <ModalPackage Package="Hafif Ticari" />
                                                                </>
                                                                )}
                                                                <a className="c-button" style={{ position: 'absolute', height: 30, fontSize: 10 }} href={() => false} onClick={() => setModalShow(true)}>SÖZLEŞME İÇERİĞİ </a>                                                             */}
                                                                </div>
                                                        </span>
                                                </div>
                                                <div className="c-services__item--image" >
                                                    <div className="c-services__item--image-container" >
                                                        <img alt="Hafif Ticari" src={s_hafifticari} />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="c-button_actions" data-aos="fade-up" data-aos-delay="1200">
                                                <a className="c-special-button" href="/tr/online-islemler/sozlesme-ara">SATINAL</a>
                                            </div> */}
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="c-col c-services__item" >
                                                <div className="c-services__item--title" >
                                                        <span >
                                                            Ağır Ticari
                                                            <div className="c-services__item--btn" >
                                                            {/* {modalShow && (
                                                                <>
                                                                    <ModalPackage Package="Ağır Ticari" />
                                                                </>
                                                                )}
                                                                <a className="c-button" style={{ position: 'absolute', height: 30, fontSize: 10 }} href={() => false} onClick={() => setModalShow(true)}>SÖZLEŞME İÇERİĞİ </a>                                                            */}
                                                                 </div>
                                                        </span>

                                                </div>
                                                <div className="c-services__item--image" >
                                                    <div className="c-services__item--image-container" >
                                                        <img alt="Ağır Ticari"
                                                            src={s_agirticari} />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="c-button_actions" data-aos="fade-up" data-aos-delay="1200">
                                                <a className="c-special-button" href="/tr/online-islemler/sozlesme-ara">SATINAL</a>
                                            </div> */}
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="c-col c-services__item" >
                                                <div className="c-services__item--title" >
                                                        <span >
                                                            İkame Araç
                                                            <div className="c-services__item--btn" >
                                                            {/* {modalShow && (
                                                                <>
                                                                    <ModalPackage Package="İkame Araç" />
                                                                </>
                                                                )}
                                                                <a className="c-button" style={{ position: 'absolute', height: 30, fontSize: 10 }} href={() => false} onClick={() => setModalShow(true)}>SÖZLEŞME İÇERİĞİ </a>                                                             */}
                                                                </div>
                                                        </span>

                                                </div>
                                                <div className="c-services__item--image" >
                                                    <div className="c-services__item--image-container"  >
                                                        <img alt="İkame Araç"
                                                            src={s_ikamearac} />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="c-button_actions" data-aos="fade-up" data-aos-delay="1200">
                                                <a className="c-special-button" href="/tr/online-islemler/sozlesme-ara">SATINAL</a>
                                            </div> */}
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            </div>
                            <div className="c-services__actions" >
                                <a className="c-special-button" href="/tr/kurumsal/mobil-assistance-kurumsal">BİZİ TANIYIN </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}


// import React from 'react';
// // Import Swiper React components
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';
// import 'swiper/css/navigation';
// import { Navigation } from 'swiper/modules';




// export default function Component() {


//     return (
//         <>
//             <br></br>
//             <div style={{ paddingLeft: 80, paddingRight: 80, }}>
//                 <Swiper
//                     slidesPerView={5}
//                     spaceBetween={4}
//                     pagination={{
//                         type: 'fraction',
//                     }}
//                     navigation={true}
//                     modules={[Navigation]}
//                     className="mySwiper"
//                 >
//                     <SwiperSlide>
//                         <div className="c-services__item" >
//                             <div className="c-services__item--title" >
//                                 <a href="/" >
//                                     <span >
//                                         Mobilya
//                                     </span>
//                                 </a>
//                             </div>
//                             <div className="c-services__item--image" >
//                                 <div className="c-services__item--image-container" >
//                                     <img alt="Mobilya" src="https://www.erciyesanadolu.com/assets/img/uploads/services/mobilya.png" />
//                                 </div>
//                             </div>
//                         </div>
//                     </SwiperSlide>
//                     <SwiperSlide>
//                         <div className="c-col c-services__item" >
//                             <div className="c-services__item--title" >
//                                 <a href="https://www.erciyesanadolu.com/tr/faaliyet-alanlari/tekstil" >
//                                     <span >
//                                         Tekstil
//                                     </span>
//                                 </a>
//                             </div>
//                             <div className="c-services__item--image" >
//                                 <div className="c-services__item--image-container" >
//                                     <img alt="Tekstil"
//                                         src="https://www.erciyesanadolu.com/assets/img/uploads/services/tekstil-1.png" />
//                                 </div>
//                             </div>
//                         </div>
//                     </SwiperSlide>
//                     <SwiperSlide>
//                         <div className="c-col c-services__item" >
//                             <div className="c-services__item--title" >
//                                 <a href="https://www.erciyesanadolu.com/tr/sektorler/kablo" >
//                                     <span >
//                                         Kablo
//                                     </span>
//                                 </a>
//                             </div>
//                             <div className="c-services__item--image" >
//                                 <div className="c-services__item--image-container" >
//                                     <img alt="Kablo"
//                                         src="https://www.erciyesanadolu.com/assets/img/uploads/services/kablo.png" />
//                                 </div>
//                             </div>
//                         </div>
//                     </SwiperSlide>
//                     <SwiperSlide>
//                         <div className="c-col c-services__item" >
//                         <div className="c-services__item--title" >
//                             <a href="https://www.erciyesanadolu.com/tr/sektorler/kimya" >
//                                 <span >
//                                     Kimya
//                                 </span>
//                             </a>
//                         </div>
//                         <div className="c-services__item--image" >
//                             <div className="c-services__item--image-container"  >
//                                 <img alt="Kimya"
//                                     src="https://www.erciyesanadolu.com/assets/img/uploads/services/kimya.png" />
//                             </div>
//                         </div>
//                     </div></SwiperSlide>
//                     <SwiperSlide>
//                         <div className="c-col c-services__item" >
//                         <div
//                             className="c-services__item--title" >
//                             <a href="https://www.erciyesanadolu.com/tr/sektorler/pazarlama"  >
//                                 <span >
//                                     Pazarlama
//                                 </span>
//                             </a>
//                         </div>
//                         <div className="c-services__item--image" >
//                             <div className="c-services__item--image-container" >
//                                 <img
//                                     alt="Pazarlama"
//                                     src="https://www.erciyesanadolu.com/assets/img/uploads/services/pazarlama.png" />
//                             </div>
//                         </div>
//                     </div>
//                     </SwiperSlide>
//                     <SwiperSlide>
//                         <div className="c-col c-services__item" >
//                         <div
//                             className="c-services__item--title" >
//                             <a href="https://www.erciyesanadolu.com/tr/sektorler/enerji"  >
//                                 <span  >
//                                     Enerji
//                                 </span>
//                             </a>
//                         </div>
//                         <div className="c-services__item--image" >
//                             <div className="c-services__item--image-container" >
//                                 <img alt="Enerji"
//                                     src="https://www.erciyesanadolu.com/assets/img/uploads/services/enerji.png" />
//                             </div>
//                         </div>
//                     </div>
//                     </SwiperSlide>
//                     <SwiperSlide>
//                         <div className="c-col c-services__item" >
//                         <div className="c-services__item--title" >
//                             <a href="https://www.erciyesanadolu.com/tr/sektorler/demir-celik" >
//                                 <span >
//                                     Demir-Çelik
//                                 </span>
//                             </a>
//                         </div>
//                         <div className="c-services__item--image" >
//                             <div className="c-services__item--image-container" >
//                                 <img alt="Demir-Çelik"
//                                     src="https://www.erciyesanadolu.com/assets/img/uploads/services/demir-celik.png" />
//                             </div>
//                         </div>
//                     </div>
//                     </SwiperSlide>
//                     <SwiperSlide>
//                         <div className="c-col c-services__item" >
//                             <div className="c-services__item--title" >
//                                 <a href="https://www.erciyesanadolu.com/tr/sektorler/lojistik-hizmet" >
//                                     <span >
//                                         Hizmet Lojistik
//                                     </span>
//                                 </a>
//                             </div>
//                             <div className="c-services__item--image"  >
//                                 <div className="c-services__item--image-container" >
//                                     <img alt="Hizmet ve Lojistik"
//                                         src="https://www.erciyesanadolu.com/assets/img/uploads/services/lojistik.png" />
//                                 </div>
//                             </div>
//                         </div>
//                     </SwiperSlide>
//                 </Swiper>
//             </div>
//         </>
//     );
// }
