import 'bootstrap/dist/css/bootstrap.min.css';
function Online() {
    return (
        <>
                <section className="c-brands u-relative" >
                    <div className="c-brands__wrapper">
                        <div className="about-lists">
                            <div className="c-section-title">
                                <div className="c-section-title__sub-title" data-aos="fade-down" data-aos-delay="600">ONLİNE İŞLEMLER</div>
                            </div>
                            <div className="container">
                                <div className="row no-gutters">
                                    <div className="col-lg-4 col-md-6 content-item" data-aos="fade-up" data-aos-delay="10">
                                        <div>

                                        </div>
                                        <h4>Bireysel</h4>
                                        <p>Bayilerimizden satın  aldığınız hizmet sözleşmelerini görüntüleyebilirsiniz. </p>
                                        <p>     
                                            <div className="c-button_actions" data-aos="fade-up" data-aos-delay="1200">
                                                <a className="c-special-button" href="/tr/online-islemler/sozlesme-ara">SÖZLEŞME ARA</a>
                                            </div>
                                        </p>
                                    </div>

                                    <div className="col-lg-4 col-md-6 content-item" data-aos="fade-up" data-aos-delay="10">
                                        <h4>Tedarikçi</h4>
                                        <p>Oto kurtarma, araç kiralama, oto lastik tamiri kapsamında tedarikçi ağımıza katılmak için başvuru yapabilirsiniz.  </p>
                                        <p>
                                            <div className="c-button_actions" data-aos="fade-up" data-aos-delay="1200">
                                                <a className="c-special-button" href="/tr/online-islemler/tedarikci-basvurusu">TEDARİKÇİ BAŞVURUSU</a>
                                            </div>
                                        </p>
                                    </div>

                                    <div className="col-lg-4 col-md-6 content-item" data-aos="fade-up" data-aos-delay="10">
                                        <h4>Sigorta Acentesi</h4>
                                        <p>Ayrıcalıklı yol yardım hizmetlerinden faydalanmak isteyen <b>Sigorta Acenteleri</b>'ni Mobil Assistance'a Davet ediyoruz' </p>

                                        <div className="c-button_actions" data-aos="fade-up" data-aos-delay="1200">
                                            <a className="c-special-button" href="/tr/online-islemler/bayilik-basvurusu">BAYİ BAŞVURUSU</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
        </>
    );
};
export default Online;