import '../../../App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

import Topbar from '../../../component/topbar';
import Faaliyet from '../../../component/faaliyet';
import Footer from '../../../component/footer';
import Hedefler from '../../../component/Pages/kurumsal/kurumsalhedefler';


import Breadcrumb from "../../../component/application/breadcrums";
import PageContainer from '../../../component/application/pageContainer';

const BCrumb = [
    {
        to: '/',
        title: 'Anasayfa',
    },
    {
        to: '/tr/kurumsal/mobil-assistance-kurumsal',
        title: 'Kurumsal'
    },
    {
        title: 'Kurumsal Hedeflerimiz',
    },
];

const Kurumsal = () => {
    AOS.init();
    return (
        <PageContainer title="Kurumsal Hedeflerimiz" description="Mobil assistance Kurumsal Hedeflerimiz ">
            <Topbar />
            <Breadcrumb title="Mobil Assistance Kurumsal Hedeflerimiz" items={BCrumb} />
            <Hedefler />
            <Faaliyet />
            <Footer />
        </PageContainer>
    );
};
export default Kurumsal;
