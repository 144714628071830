import test from '../../../assets/img/kurumsal/mobilassistance_amac.jpg'
const hakkimizda = () => {
    return (
        <div className="o-container o-container--page u-relative">
            <div className="c-bg-border">
                <div className="o-container">
                    <div className="c-col__row">
                        <div className="c-col-4 c-col"></div>
                        <div className="c-col-4 c-col"></div>
                        <div className="c-col-4 c-col"></div>
                        <div className="c-col-4 c-col"></div>
                    </div>
                </div>
            </div>
            <div className="o-container__wrapper">
                <div className="page-content">
                    <img alt="Mobil Assistance Kurumsal" className="align-right" src={test} />
                     <p>
                     2019'da yola çıkan ve her geçen gün büyüyen bir yol yardım firması olarak, misyonumuz sizin güveninizle şekilleniyor. 
                     </p>
                     <p>
                     Sigorta şirketlerinden bağımsız olarak, doğrudan sigorta acentelerinin nihai müşterileri için hizmet sunuyoruz. 
                     </p>
                     <p>
                     Mobil Assistance Yol Yardım Hizmetleri olarak amacımız, aracınız ile yolda kaldığınızda size en hızlı ve en güvenilir şekilde yol yardımı hizmeti vermek.
                     </p>
                     <p>
                     Mobil Assistance Yol Yardım Hizmetleri, Assistance sektöründe güvenin sarsıldığı, sigorta acentelerinin müşterilerine yeterli hizmet verme konusunda sorunlar yaşadığı, çağrı merkezlerine ulaşmanın saatler sürebildiği, araç kurtarıcı hizmetlerinin aksadığı, ikame araç temininin haftaları bulduğu karanlık bir dönemde sektöre yeni ve etkili bir nefes getirmiştir.
                     </p>
                     <p>
                     Bu zorlu zamanlarda Mobil Assistance Yol Yardım Hizmetleri, müşterilerine sağladığı hızlı, kaliteli ve güvenilir hizmetlerle sektördeki güveni yeniden en üst düzeye çıkarmıştır.
                     </p>
                     <p>
                     Mobil Assistance Yol Yardım Hizmetleri; güven, özen ve empati temelinde şekillenen güçlü teknoloji ve iyi düşünülmüş hizmetleriyle sektörde öncü bir asistans destek hizmeti sunmaktadır. 
                     </p>
                     <p>
                     Kişiye özel çözümleri ve entegre hizmet paketleri ile sigorta acentelerinin nihai müşterileri için 7 gün 24 saat artı değer yaratır.
                     </p>
                     <p>
                     Başarımızın kökeninde bilgi birikimi ve hizmet anlayışı yatar. Müşterilerimizin güvenine layık olmanın bilinci ve sorumluluğu ile büyümeye ve hizmetlerimizi geliştirmeye devam etmekteyiz. 
                     </p>
                     <p>
                     Türkiye’nin 81 ilinde 4000’i aşkın profesyonel tedarikçilerimiz ve hizmet sağlayan paydaşlarımızla karşılıklı fayda anlayışı içinde tek ortak hedefimiz hızlı, kaliteli ve hatasız hizmet sunmaktır. 
                     </p>
                     <p>
                     Tedarik zincirimizin her aşamasında, şeffaflık, güvenilirlik ve sürekli iyileştirme ilke ve değerlerini benimseyerek, müşteri memnuniyetini en üst seviyeye çıkarmak temel prensiplerimiz arasında yer alıyor. 
                     </p>
                     <p>
                     Yolda kaldığınızda, aracınızı en hızlı şekilde kurtarmak için buradayız.
                     </p>
                </div>
            </div>
        </div>
    );
};

export default hakkimizda;