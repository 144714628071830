import '../../../App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

import Topbar from '../../../component/topbar';
import Footer from '../../../component/footer';
import InlinePages from '../../../component/Pages/paketler/paketagirticari';


import Breadcrumb from "../../../component/application/breadcrums";
import PageContainer from '../../../component/application/pageContainer';
 
const BCrumb = [
    {
        to: '/',
        title: 'Anasayfa',
    },
    {
        to: "/tr/paketler",
        title: 'Yol Yardım Paketleri',
    }, 
    {
        to: "/tr/paketler-agir-ticari-yol-yardim-paketleri",
        title: 'Motosiklet Yol Yardım Paketleri',
    }, 
];

const PaketAgirTicari = () => {
    AOS.init();
    return (
        <PageContainer title="Ağır Ticari Yol Yardım Paketleri" description="Mobil assistance Ağır Ticari Yol Yardım Paketleri Sayfası ">
            <Topbar />
            <Breadcrumb title="Ağır Ticari Yol Yardım Paketleri" items={BCrumb} />
            <InlinePages /> 
            <Footer />
        </PageContainer>
    );
};
export default PaketAgirTicari;
