import CagriMerkezi from '../../../assets/img/hizmetler/hizmetler_cagrimerkezi.gif'

const HizmetlerCagriMerkezi = () => {
    return (
        <>
            <div className="o-container o-container--page u-relative">
                <div className="o-container__wrapper">
                    <div className="page-content">
                        <>
                        <img alt="Mobil Assistance Çağrı Merkezi Hizmeti" className="align-right" src={CagriMerkezi} width={600} />
                            <p>Sorunsuz İletişim ve Müşteri Deneyimi: Çağrı Merkezi Hizmetleri</p>
                            <p>
                            7/24 aktif olarak hizmet veren çağrı merkezi ekibimiz, aracınız yolda kaldığında ihtiyaçlarınızı anında karşılamak için hazır bekliyor.
                            </p>
                            <p>
                            Mobil Assistance Yol Yardım Hizmetleri olarak, müşterilerinizle güçlü iletişim kurmanız için size özel çağrı merkezi hizmetleri sunuyoruz.                            </p>
                            <p>
                            Alanlarında uzman ekiplerimiz, yüksek kaliteli müşteri hizmetlerini sağlamak için son teknoloji sistemlerle donatılmış modern bir çağrı merkezinde çalışmaktadır. 
                            </p>
                            <p>
                            Sunduğumuz profesyonel çağrı merkezi hizmetleri sayesinde, müşterilerinizin sizinle olan etkileşimden memnun kalmasını sağlar ve markanızın büyümesini destekler.
                            </p>
                            <p>
                            Çağrı Merkezimizin en önemli unsurlarından biri müşteri deneyimidir. Müşterilerinizin taleplerine zamanında yanıt vererek onları memnun etmek bizim önceliğimizdir. 
                            </p>
                            <p>
                            Ekip olarak; her seviyede sorularınıza cevap verebilen ekipler oluşturduk böylece müşterilerinizin konforunu sağlıyoruz. 
                            </p>
                            <p>
                            Müşterilerin memnuniyetlerini sürekli tutmak için uzman ekibimizle beraber aktif geri bildirim alarak personelin performansını değerlendiriyor ve sürekli iyileştirmeler yapıyoruz. 
                            </p>
                            <p>
                            Birlikte çalışmak için sizleri de Mobil Assistance Yol Yardım Hizmetleri ailesine bekliyoruz ! 
                            </p>
                            <p>
                            Sizler de işletmenizin büyümeye devam etmesine yardımcı olmak istiyorsanız bize bugün ulaşarak daha fazla bilgi alabilirsiniz!
                            </p>
                        </>
                    </div>
                </div >
            </div >
        </>
    );
};

export default HizmetlerCagriMerkezi;