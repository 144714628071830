
import 'bootstrap/dist/css/bootstrap.min.css';
import dealer from '../../../assets/img/svg/ico_Dealer.svg'
import search from '../../../assets/img/svg/ico_search.svg'
import basvuru from '../../../assets/img/svg/bayibasvuru.svg'
import giris from '../../../assets/img/svg/kurumsal/ico_svg_kurumsal_corporate.svg'

import 'swiper/css';
import 'swiper/css/navigation';



const OnlineIslemlerMain = () => {
    return (
        <div className="o-container o-container--page u-relative">
            <div className="o-container__wrapper">
                <div className="c-box c-box--list">
                    <a className="c-box__item c-box__item--list c-box__item--big" href="/tr/online-islemler/sozlesme-ara">
                        <div className="c-box__img">
                            <img src={search} alt="Sözleşme Ara" width={100} />
                        </div>
                        <div className="c-box__text">
                            <p>Sözleşme Ara</p>
                        </div>
                    </a>
                    <a className="c-box__item c-box__item--list c-box__item--big" href="/tr/online-islemler/bayilik-basvurusu">
                        <div className="c-box__img">
                            <img src={basvuru} alt="Bayilik Başvurusu" width={100} />
                        </div>
                        <div className="c-box__text">
                            <p>Bayi Başvurusu</p>
                        </div>
                    </a>
                    <a className="c-box__item c-box__item--list c-box__item--big" href="/tr/online-islemler/tedarikci-basvurusu">
                        <div className="c-box__img">
                            <img src={dealer} alt="Tedarikçi Başvurusu" width={100} />
                        </div>
                        <div className="c-box__text">
                            <p>Tedarikçi Başvurusu</p>
                        </div>
                    </a>
                    <a className="c-box__item c-box__item--list c-box__item--big" href="/tr/online-islemler/bayi-agimiz">
                        <div className="c-box__img">
                            <img src={giris} alt="Bayi Ağımız" width={100} />
                        </div>
                        <div className="c-box__text">
                            <p>Bayi Ağımız</p>
                        </div>
                    </a>
                    <a className="c-box__item c-box__item--list c-box__item--big" href="/tr/online-islemler/tedarikci-basvurusu">
                        <div className="c-box__img">
                            <img src={dealer} alt="Tedarikçi Başvurusu" width={100} />
                        </div>
                        <div className="c-box__text">
                            <p>Tedarikçi Ağımız</p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default OnlineIslemlerMain;