
import KisiyeOzel from '../../../assets/img/hizmetler/hizmetler_kisiyeozel.jpg'

const HizmetlerKisiyeOzel = () => {
    return (
        <>
            <div className="o-container o-container--page u-relative">
                <div className="o-container__wrapper">
                    <div className="page-content">
                        <>
                        <img alt="Mobil Assistance Kişiye Özel Hizmetler" className="align-right" src={KisiyeOzel} />
                        
                        <p>
                        Mobil Assistance Yol Yardım Hizmetleri olarak, kurumsal müşterilerimize özel tasarlanmış genişletilebilir yol yardım paketleri sunuyoruz.                         </p>
                        <p>
                        İşletmelerin ve kuruluşların ihtiyaçlarına uygun çözümler sağlayarak, operasyonel verimliliği artırmayı hedefliyoruz. 
                        </p>
                        <p>
                        Sunduğumuz hizmetler arasında, araçların acil durumlar ve arıza durumlarında hızlı ve güvenilir destek almasını sağlamak için özelleştirilebilir seçenekler bulunmaktadır.
                        </p>
                        <p>
                        Her işletmenin özel gereksinimlerini anlayarak, profesyonel destek ve çözümler sunuyoruz. 
                        </p>
                        <p>
                        Müşterilerimize sağladığımız bu hizmetlerle, güvenilirliği ve operasyonel sürekliliği en üst seviyeye çıkarmayı amaçlıyoruz. 
                        </p>
                        <p>
                        Detaylı bilgi ve paket seçenekleri için bizimle iletişime geçebilirsiniz.
                        </p>
                        </>
                    </div>
                </div >
            </div >
        </>
    );
};

export default HizmetlerKisiyeOzel;