const Mss  = () => {
return (
    <>
    <div className="mss">
    <h2>MESAFELİ SATIŞ SÖZLEŞMESİ</h2>
    <p>
    MOBIL ASSISTANCE SERVİS HİZMETLERİ DENETİM DANIŞMANLIK TİCARET LTD.ŞTİ (sözleşmede “MOBİL ASSİSTANCE ” olarak anılacaktır), Oteller, Hava yolu firmaları, Oto kiralama firmaları, Oto lastik ve tamir firmaları, Çekici firmaları, çekiciler ve diğer yol yardım araçları (bundan sonra “Servis Sağlayıcıları” olarak da anılacaktır) araçları herhangi bir sebeple yolda kalan müşterileri (bundan sonra “MÜŞTERİ” olarak da anılacaktır) en yakın oto servise taşınmasını sağlayan ve o an müşterinin ihtiyacı olan hizmetleri veren yol yardım şirketidir. MOBİL ASSİSTANCE ve Müşteri, birlikte “Taraflar” olarak da anılacaktır.
    </p>
    <h2>GENEL ŞARTLAR, HİZMETLER, BAŞVURU VE ÜYELİK</h2>
    <p>
    MOBİL ASSİSTANCE Hususi kullanım otomobil ve Hafif ticari araçlar için araçlara, sözleşme geçerli olduğu tarihten itibaren 1 yıl süreli MOBİL ASSİSTANCE YOL YARDIM HİZMET PAKETİ veya İKAME ARAÇ HİZMET PAKETİNİ satın alan müşterilerine Türkiye Cumhuriyeti’nin adalar hariç tüm karayolları olmak üzere 81 ilinde uzman kadrosuyla 7 gün 24 saat ücretsiz yol yardım hizmeti ve ikame araç hizmeti veren bir yol yardım şirketidir. MOBİL ASSİSTANCE üyeliği olan Müşteri’lerine aşağıdaki paket seçeneklerine göre satın almış oldukları paketlere göre sözleşme kapsamında hizmet verilir.
    </p>
    <h2>1.PAKET TÜRLERİNE GÖRE YARDIM HİZMETLERİ;</h2>
    <h2>1.A. YOL YARDIM PAKETİ İÇERİKLER</h2>
    <p>1. Mobil Assistance bilgileri teminat başlamadan önce bildirilen Hususi Kullanım binek ve Kamyonet tipi MODEL YILI 20 YAŞI AŞMAMIŞ (minibüs, panel Van, back-up, ambulans, midibüs, kamyon, çekici, traktör, otobüs, açık kasa kamyonet, kapalı kasa kamyonet, uzun ve kısa şase kamyonet, ticari taksi, kiralık araç tipi ticari amaçla kullanılan araçlar hariç) araçlardır.</p>
    <p>2. Toplu mal ve insan taşımacılığında kullanılmamış, şoför ile veya şoför olmaksızın kiralanmamış, taşıtlardır.</p>
    <p>Sözleşme yürürlüğe giriş tarihinden itibaren 1 hafta (7 gün) sonra geçerli olacaktır. Hizmetten yararlanacak müşterinin aracı arıza veya kaza nedeni ile ikinci kez çekici hizmeti, iki olay arasında 1 hafta (7 gün) geçmiş olması durumunda verilecektir. </p>
    <p>3. Arıza veya Kaza Anında Aracın Çekilmesi Hizmeti</p>
    <p>Aracın çalışmasına veya yürüğüne engel bir mekanik arıza veya kaza durumunda Mobil Assistance tarafından aracın en yakın oto tamir servisine naklini, arıza anında yılda bir kez 500 TL limite kadar kaza anında yılda iki kez toplam 1000 tl limite kadar temin edecektir. Hizmetten yararlanacak müşterinin en yakın oto tamir servisi dışında başka bir servise çekilmesini talep etmesi durumunda oluşacak ücret farkı hizmetten yararlanacak müşteri tarafından karşılanacaktır.  Arıza veya kaza anında aracın çekilmesi, hizmet maliyeti dışında kalan köprü geçişleri, ücretli geçişler, otopark bedelleri vb. bedeller hizmetten yararlanacak müşteri tarafından karşılanacaktır. Teminatların aşılması durumunda aşılan kısım hizmetten yararlanacak müşteri tarafından ödenecektir. Toplam 1.000 tl limitin aşılması durumunda hizmet sözleşmesi sona ermiş olacaktır. Aracın kaza sonucu devrilmesi, yan yatması veya başka bir seviyeye düşmesi durumunda, aracın çekilerek veya vinç ile bulunduğu yerden kurtarılarak yoluna devam etmesi için uygun bir yere konulması 1000 TL limite ve yılda 1 defaya kadar sağlanacaktır. Aracın bagaj ve yükünden dolayı çekilmesine veya kurtarılmasına engel bir durum var ise, bagaj veya yükün boşaltılmasından Mobil Assistance sorumlu değildir:</p>

    <h2>1.B. YOL YARDIM ELİT PAKET İÇERİKLERİ</h2>
    <p>1. Mobil Assistance bilgileri teminat başlamadan önce bildirilen Hususi Kullanım binek ve Kamyonet tipi (minibüs, panel Van, back-up, ambulans, midibüs, kamyon, çekici, traktör, otobüs, açık kasa kamyonet, uzun şase kamyonet, ticari taksi, kiralık araç tipi ticari amaçla kullanılan araçlar hariç) araçlardır.</p>
    <p>2. Toplu mal ve insan taşımacılığında kullanılmamış, şoför ile veya şoför olmaksızın kiralanmamış, taşıtlardır.</p>
    <p>Sözleşme yürürlüğe giriş tarihinden itibaren 1 hafta (7 gün) sonra geçerli olacaktır. Hizmetten yararlanacak müşterinin aracı arıza veya kaza nedeni ile ikinci kez çekici hizmeti, iki olay arasında 1 hafta (7 gün) geçmiş olması durumunda verilecektir. </p>
    <p>3. Arıza veya Kaza Anında Aracın Çekilmesi Hizmeti</p>
    <p>Aracın çalışmasına veya yürüğüne engel bir mekanik arıza veya kaza durumunda Mobil Assistance tarafından aracın en yakın oto tamir servisine naklini, arıza anında yılda iki kez ve kaza anında yılda iki kez en yakın oto tamir servisine toplam yıllık 1000 tl limite kadar temin edecektir. Hizmetten yararlanacak müşterinin en yakın oto tamir servisi dışında başka bir servise çekilmesini talep etmesi durumunda oluşacak ücret farkı hizmetten yararlanacak müşteri tarafından karşılanacaktır.  Arıza veya kaza anında aracın çekilmesi, hizmet maliyeti dışında kalan köprü geçişleri, ücretli geçişler, otopark bedelleri vb. bedeller hizmetten yararlanacak müşteri tarafından karşılanacaktır. Teminatların aşılması durumunda aşılan kısım hizmetten yararlanacak müşteri tarafından ödenecektir. Toplam 1.000 tl limitin aşılması durumunda hizmet sözleşmesi sona ermiş olacaktır. Aracın kaza sonucu devrilmesi, yan yatması veya başka bir seviyeye düşmesi durumunda, aracın çekilerek veya vinç ile bulunduğu yerden kurtarılarak yoluna devam etmesi için uygun bir yere konulması 1000 TL limite ve yılda 1 defaya kadar sağlanacaktır. Aracın bagaj ve yükünden dolayı çekilmesine veya kurtarılmasına engel bir durum var ise, bagaj veya yükün boşaltılmasından Mobil Assistance sorumlu değildir.</p>
    <h2>1.C. HAFİF TİCARİ YOL YARDIM PAKET İÇERİKLERİ</h2>
    <p>1. Mobil Assistance bilgileri teminat başlamadan önce bildirilen ve MODEL YILI 20 YAŞI AŞMAMIŞ Ticari taksi, Kiralık araç, Minibüs, Panel Van, Back-up, Midibüs (18-20 Koltuk 4500 kg aşmamış), Acık-Kapalı kasa kamyonet, Traktör (90 beygiri aşmamış 5000 kg aşmamış traktör) tipi ticari amaçlı kullanılan araçlardır</p>
    <p>2. Teminat sahibi araca ait eklentiler (Römork, Treyler, Dorse), inşaata yönelik endüstriyel araçlar, Açık/Kapalı kasa kamyonlar, Otobüsler, Midibüs (21- 30 Koltuk 4500 kg aşmış) Askeri araçlar, Sürücü kurslarına ait araçlar teminat kapsamı dışındadır.</p>
    <p>Sözleşme yürürlüğe giriş tarihinden itibaren 1 hafta (7 gün) sonra geçerli olacaktır. Hizmetten yararlanacak müşterinin aracı arıza ve kaza nedeni ile ikinci kez çekici hizmeti, iki olay arasında 1 hafta (7 gün) geçmiş olması durumunda verilecektir. </p>
    <p>Traktör tipi araçların arıza veya kaza yapması durumunda Tarlada, Dağlık ve arazi yollarında hizmet verilmeyecektir. Çekicinin ulaşabileceği emniyetli kara yolu olması durumunda hizmet verilecektir. Kurtarma maliyetleri karşılanmayıp çekici ücreti karşılanacaktır.  </p>
    <p>3. Arıza veya Kaza Anında Aracın Çekilmesi Hizmeti</p>
    <p>Aracın çalışmasına veya yürür üne engel bir mekanik arıza veya kaza durumunda Mobil Assistance tarafından aracın en yakın tamirhaneye naklini, arıza anında yılda bir kez en yakın oto tamir servisine 750 TL limite kadar ve kaza anında yılda iki kez en yakın tamirhaneye 750 TL limite kadar temin edecektir. Hizmetten yararlanacak müşterinin en yakın tamirhanenin dışında başka bir servise çekilmesini talep etmesi durumunda oluşacak ücret farkı hizmetten yararlanacak müşteri tarafından karşılanacaktır. İl veya ilçe sınırları içerisinde yetkili servis yoksa en yakın tamirhaneye nakli sağlanacaktır. Arıza veya kaza anında aracın çekilmesi, hizmet maliyeti dışında kalan köprü geçişleri, ücretli geçişler, otopark bedelleri vb. bedeller hizmetten yararlanacak müşteri tarafından karşılanacaktır. Teminatların aşılması durumunda aşılan kısım hizmetten yararlanacak müşteri tarafından ödenecektir. Aracın devrilmesi, yan yatması veya başka bir seviyeye düşmesi durumunda, aracın çekilerek veya vinç ile bulunduğu yerden kurtarılarak yoluna devam etmesi için uygun bir yere konulması olay başı 750 TL limite ve yılda 2 defaya kadar sağlanacaktır. Aracın bagaj ve yükünden dolayı çekilmesine veya kurtarılmasına engel bir durum var ise, bagaj veya yükün boşaltılmasından Mobil Assistance sorumlu değildir. Yolcu taşımacılığı yapan araçlarda yolcuların naklinden Mobil Assistance sorumlu değildir.</p>
    <h2>1.D. HAFİF TİCARİ YOL YARDIM ELİT PAKET İÇERİKLERİ</h2>
    <p>1. Mobil Assistance bilgileri teminat başlamadan önce bildirilen ve MODEL YILI 20 YAŞI AŞMAMIŞ Ticari taksi, Kiralık araç, Minibüs, Panelvan, Pick-up, Midibüs (18-20 Koltuk 4500 kg aşmamış), Acık-Kapalı kasa kamyonet, Panalvan, Traktör (90 beygiri aşmamış 5000 kg aşmamış traktör)  tipi ticari amaçlı kullanılan araçlardır</p>
    <p>2. Teminat sahibi araca ait eklentiler (Römork, Treyler, Dorse), inşaata yönelik endüstriyel araçlar, Açık/Kapalı kasa kamyonlar, Otobüsler, Midibüs (21- 30 Koltuk 4500 kg aşmış) Askeri araçlar, Sürücü kurslarına ait araçlar teminat kapsamı dışındadır.</p>
    <p>Sözleşme yürürlüğe giriş tarihinden itibaren 1 hafta (7 gün) sonra geçerli olacaktır. Hizmetten yararlanacak müşterinin aracı arıza ve kaza nedeni ile ikinci kez çekici hizmeti, iki olay arasında 1 hafta (7 gün) geçmiş olması durumunda verilecektir. </p>
    <p>Traktör tipi araçların arıza veya kaza yapması durumunda Tarlada, Dağlık ve arazi yollarında hizmet verilmeyecektir. cekicinin ulaşabileceği emniyetli kara yolu olması durumunda hizmet verilecektir. Kurtarma maliyetleri karşılanmayıp cekici üçreti karşılanacaktır.  </p>
    <p>3. Arıza veya Kaza Anında Aracın Çekilmesi Hizmeti</p>
    <p>Aracın çalışmasına veya yürür üne engel bir mekanik arıza veya kaza durumunda  Mobil Assistance tarafından aracın en yakın tamirhaneye naklini, arıza anında yılda iki kez en yakın oto tamir servisene (olay başı 750 TL limit ile yılda toplam 1.500 TL) ve kaza anında yılda iki kez en yakın tamirhaneye (olay başı 750 TL limit ile yılda toplam 1.500 TL) limite kadar temin edecektir. Hizmetten yararlanacak müşterinin en yakın tamirhanenin dışında başka bir servise çekilmesini talep etmesi durumunda oluşacak ücret farkı hizmetten yararlanacak müşteri tarafından karşılanacaktır. İl veya ilçe sınırları içerisinde yetkili servis yoksa en yakın tamirhaneye nakli sağlanacaktır. Arıza veya kaza anında aracın çekilmesi, hizmet maliyeti dışında kalan köprü geçişleri, ücretli geçişler, otopark bedelleri vb. bedeller hizmetten yararlanacak müşteri tarafından karşılanacaktır. Teminatların aşılması durumunda aşılan kısım hizmetten yararlanacak müşteri tarafından ödenecektir. Aracın devrilmesi, yan yatması veya başka bir seviyeye düşmesi durumunda, aracın çekilerek veya vinç ile bulunduğu yerden kurtarılarak yoluna devam etmesi için uygun bir yere konulması olay başı 750 TL limite ve yılda 2 defaya kadar sağlanacaktır. Aracın bagaj ve yükünden dolayı çekilmesine veya kurtarılmasına engel bir durum var ise, bagaj veya yükün boşaltılmasından Mobil Assistance sorumlu değildir. Yolcu taşımacılığı yapan araçlarda yolcuların naklinden Mobil Assistance sorumlu değildir.</p>
    <h2>1.E. YOL YARDIM (YETKİLİ SERVİS) PAKET İÇERİKLERİ</h2>
    <p>1. Mobil Assistance bilgileri teminat başlamadan önce bildirilen MODEL YILI 15 YAŞI AŞMAMIŞ Hususi Kullanım binek ve Kamyonet tipi (minibüs, panelvan, pick-up, ambulans, midibüs, kamyon, cekici, traktör, otobüs, açık kasa kamyonet, uzun şase kamyonet, ticari taksi, kiralık araç tipi ticari amaçla kullanılan araçlar hariç) araçlardır.</p>
    <p>2. Toplu mal ve insan taşımacılığında kullanılmamış, şoför ile veya şoför olmaksızın kiralanmamış, taşıtlardır.</p>
    <p>Sözleşme yürürlüğe giriş tarihinden itibaren 20 gün sonra geçerli olacaktır. Ancak aracın trafik sigortası ile birlikte veya tescil tarihi ile aynı gün hizmet sözleşmesinin düzenlenmesi durumunda 1 hafta ( 7 gün ) sonra geçerli olacaktır. Hizmetten yararlanacak müşterinin aracı  arıza veya kaza nedeni ile ikinci kez çekici hizmeti, iki olay arasında 1 hafta (7 gün) geçmiş olması durumunda verilecektir.</p>
    <p>3. Arıza veya Kaza Anında Aracın Çekilmesi Hizmeti</p>
    <p>Aracın çalışmasına veya yürürüne engel bir mekanik arıza veya kaza durumunda Mobil Assistance tarafından aracın en yakın Yetkili servise veya oto tamir servisine naklini, arıza anında yılda iki kez ve kaza anında yılda iki kez en yakın yetkili servise veya oto tamir servisine toplam yıllık 1.500 tl limite kadar temin edecektir. Hizmetten yararlanacak müşterinin en yakın yetkili servis dışında başka bir servise çekilmesini talep etmesi durumunda oluşacak ücret farkı hizmetten yararlanacak müşteri tarafından karşılanacaktır.  Arıza veya kaza anında aracın çekilmesi, hizmet maliyeti dışında kalan köprü geçişleri, ücretli geçişler, otopark bedelleri vb. bedeller hizmetten yararlanacak müşteri tarafından karşılanacaktır. Teminatların aşılması durumunda aşılan kısım hizmetten yararlanacak müşteri tarafından ödenecektir. Toplam 1.500 tl limitin aşılması durumunda hizmet sözleşmesi süresi sona ermiş olacaktır. Aracın kaza sonucu devrilmesi, yan yatması veya başka bir seviyeye düşmesi durumunda, aracın çekilerek veya vinç ile bulunduğu yerden kurtarılarak yoluna devam etmesi için uygun bir yere konulması 1000 TL limite ve yılda 2 defaya kadar sağlanacaktır. Aracın bagaj ve yükünden dolayı çekilmesine veya kurtarılmasına engel bir durum var ise, bagaj veya yükün boşaltılmasından Mobil Assistance sorumlu değildir. Aracın çekme ve kurtarma hizmetleri aşağıdaki kapsamda verilecektir:</p>
    <h2>1.F. İKAME ARAÇPAKET İÇERİKLERİ</h2>
    <p>1. Mobil Assistance  bilgileri teminat başlamadan önce bildirilen Hususi Kullanım binek ve Kamyonet tipi (minibüs, panelvan, pick-up, ambulans, midibüs, kamyon, cekici, traktör, otobüs, açık kasa kamyonet, uzun şase kamyonet, ticari taksi, kiralık, kiralık araç tipi ticari amaçla kullanılan araçlar hariç) araçlardır.</p>
    <p>2. Toplu mal ve insan taşımacılığında kullanılmamış, şoför ile veya şoför olmaksızın kiralanmamış, taşıtlardır.</p>
    <p>Sözleşme yürürlüğe giriş tarihinden itibaren 1 hafta (7 gün) sonra geçerli olacaktır. Hizmetten yararlanacak müşterinin aracı arıza veya kaza nedeni ile ikinci kez çekici hizmeti, iki olay arasında 1 hafta (7 gün) geçmiş olması durumunda verilecektir. </p>
    <p>3.Sözleşme geçerlilik süresince gerçekleşen bir çift taraflı kaza sonucu araçta meydana gelen, aracın trafikte emniyetli sürüşüne engel olan ve/veya aracın hareketsizliğine neden olan hasarlar için ve aşağıdaki koşullarda ikame kiralık araç verilir.</p>
    <p>3.1.İkame kiralık araç hizmeti, çift taraflı kaza sonucu meydana gelen hasarlar için aracın serviste onarım ihtiyacı oluştuğu durumlarda verilecektir. Eksper onayı ile trafikte emniyetli sürüşe engel teşkil etmediği belirlenen hasarlar için ikame araç hizmeti verilmez (Kaporta, karoseri ve tampon üzerindeki çizikler, göçükler veya koltuk döşeme Mini Hasar Onarım Hizmeti kapsamındaki onarımları gibi).</p>
    <p>3.2.İkame kiralık araç, hasarlı aracın servisteki onarım süresinin 3. maddeye göre yapılacak tespitinin 24 saati geçtiği durumlarda (pazar günleri hariç) verilir.</p>
    <p>3.3.MOBIL ASSİSTANCE aracın tamir süresinin tespitinde kendi uzmanını görevlendirebilir, gerektiğinde eksper ve tamiratı gerçekleştirecek tamirciden görüş alır, ancak İkame kiralık araç hizmetinden yararlanma süresinin tespitinde MOBIL ASSİSTANCE kararı esastır.</p>
    <p>3.4.Tamiratın mutabık kalınan süreden kısa sürmesi durumunda ikame araç iade edilecektir.</p>
    <p>3.5.Araç sahibi ile karşılıklı mutabık kalınan tamir süresi ile ve her koşulda en fazla sözleşmede belirtilen gün kadar (olay başına 7 gün) ve yılda en fazla bir kez mümkün olabilen durumlarda MOBIL ASSISTANCE’ e tarafından belirlenen rent a car firmasının filosunda bulunan araçlardan sözleşmede belirtilen aracın özelliklerine en yakın ve en fazla B sınıfı olmak üzere ikame araç sağlanır.</p>
    <p>2. Patlayan Lastiğin Değiştirilmesi Veya Benzin Bitimi Nedeniyle Aracın Çekilmesi Hizmeti;</p>
    <p>Hizmetten Yararlanacak Müşteri'nin arzusu doğrultusunda, aşağıdaki seçeneklerden biri karşılanacaktır. Bu hizmetimiz YOL YARDIM, YOLYARDIM ELİT, YOL YARDIM (YETKİLİ SERVİS) paketlerimiz için geçerlidir. HAFİF TİCARİ ve HAFİF TİCARİ PAKET’ ler için geçerli değildir.</p>
    <p>2.1- Herhangi bir nedenle lastiği patlayan veya zarar gören araca en uygun hizmet birimi gönderilir. Gönderilen hizmet birimi, olay yeri uygunsa ve hizmetten yararlanacak müşterinin aracında yedek lastik var ise aracın lastiği değiştirilir. Olay yeri uygun değil ise aracın en yakın lastikçiye veya servise naklini organizasyon-indirimli olarak sağlar. Bu hizmet yılda bir kez yıllık limit dâhilinde sağlanacaktır. Teminatların aşılması durumunda aşılan meblağ kadar olan ödemeyi hizmetten yararlanacak müşteri yapacaktır. Lastiğin tamiri ile ilgili malzeme ücreti müşteri tarafından ödenir.</p>
    <p>2.2- Aracın benzinin bitmesi durumunda araç en yakın benzin istasyonuna yılda bir kez yıllık limit dâhilinde çekilecektir. Teminatların aşılması durumunda aşılan kısım Hizmetten Yararlanacak Müşteri tarafından ödenecektir. Bu hizmetimiz Türkiye genelinde ve 7/24 sağlanmaktadır</p>
    <p>3. Bir yıl süreli MOBİL ASSİSTANCE Yol Yardım ve İkame araç paketi www.mobilassistance.com  web sitesi üzerinden satın al butonunu kullanarak gerekli bilgiler girildikten sonra satın alınabilir. </p>
    <p>4. MÜŞTERİ, MOBİL ASSİSTANCE yol yardım ve ikame araç hizmetine ihtiyaç duyduğu anda 7 gün 24 saat 0850 255 02 03 numaralı çağrı merkezini arayarak talepte bulunması gerekir.</p>
    <p>5. Yol yardım hizmeti şehir içinde iseniz 30 dakika şehir dışı veya kırsal kesimde iseniz 60 dakika içerisinde bulunduğunuz konuma ulaşır. Bu süreler hava şartlarının ağır olduğu veya trafiğin yoğun olduğu zamanlarda farklılık gösterebilir.</p>
    <p>6. MOBİL ASSİSTANCE yol yardım ve ikame araç hizmet paketi aracın ruhsat sahibi ve plakası üzerine tanımlanır.</p>
    <p>7. Aracın ruhsat sahibi değiştiğinde MOBİL ASSİSTANCE üyeliği sona erer. Müşteri bu üyeliğini başkasına ait bir araca devredemez veya ücret iadesi alamaz. Müşteri’nin MOBİL ASSİSTANCE satın almış olduğu aracın herhangi bir sebeple plakası değişmesi durumunda çağrı merkezini arayarak plaka değişikliğini gerçekleştirebilir.</p>
    <p>8. Müşteri çağrı merkezini aramadan aracını kendi imkanıyla çektirmesi durumunda hizmet sözleşmesinden faydalanamaz. Oluşacak zararlardan MOBİL ASSİSTANCE sorumlu tutulamaz.</p>
    <p>9. MOBİL ASSİSTANCE lüzum görmesi durumunda hizmetten yararlanacak müşteriden gerekli evrak belge ve dokümanları ( olay yeri resimleri, araç ruhsatı, kullanıcı ehliyet bilgileri, sözleşme örneği ) isteyebilir.</p>
    <p>10. MOBİL ASSİSTANCE; Hizmetten yararlanacak müşterinin yanlış  veya gerçeğe aykırı beyanı, iyi niyeti suiistimali ve art niyetli davranışı tespit edilmesi durumunda sözleşmeyi tek taraflı olarak fesih edilebilir.  </p>
    <p>11. MÜŞTERİ her zaman kişisel bilgilerini çağrı merkezini arayarak güncelleyebilir.</p>
    <p>12. MÜŞTERİNİN gelen servis sağlayıcısını beklememesi veya gelen servis sağlayıcısını geçerli bir sebep olmaksızın kabul etmemesi durumunda hizmetten faydalanmış sayılır.</p>
    <p>13. MOBİL ASSİSTANCE’ in sunduğu hizmetten yararlanan MÜŞTERİ, Uygulamayı hukuka ve amacına uygun şekilde kullanmayı ve Uygulama dâhilinde yaptığı her işlem ve eylemdeki hukuki sorumluluğunun kendisine ait olduğunu kabul eder. Uygulama aracılığıyla ve/veya dâhilinde, işbu Koşullara ve hukuka aykırı olarak gerçekleştirdiği herhangi bir işlem, eylem ve/veya faaliyetleri nedeniyle MOBİL ASSİSTANCE doğrudan veya dolaylı olarak hiçbir şekilde sorumlu tutulamaz.</p>
    <p>14. MOBİL ASSİSTANCE, MÜŞTERİ’den kaynaklanan teknik aksaklıklar sebebiyle veya yol yardıma ihtiyaç duyduğu ilk anda, MOBİL ASSİSTANCE çağrı merkezini aramaması nedeniyle hizmetten faydalanılamaması durumunda işbu Koşullar ile belirlenen yükümlülüklerinden herhangi birini ifa edememesinden dolayı sorumlu tutulamayacak ve kendisinden herhangi bir nam altında herhangi bir talepte bulunulamayacaktır.</p>
    <p>15. MÜŞTERİ, Uygulama aracılığıyla veya Uygulama dâhilinde, işbu Koşullara ve hukuka aykırı olarak sürücü ve üçüncü kişiler tarafından gerçekleştirilen herhangi bir işlem, eylem ve faaliyetleri nedeniyle MOBİL ASSİSTANCE ‘in doğrudan veya dolaylı olarak hiçbir şekilde sorumlu tutulamayacağını kabul eder.</p>
    <p>16.MÜŞTERİ, Uygulama aracılığıyla MOBİL ASSİSTANCE tarafından sunulan hizmetlerden yararlanabilmek amacıyla kullandığı sistem erişim araçlarının (kullanıcı ismi, şifre v.b.) güvenliği, saklanması, üçüncü kişilerin bilgisinden uzak tutulması ile ilgili tüm sorumluluğun tamamen kendisine ait olduğunu kabul eder</p>
    <p>17. MÜŞTERİ, Uygulama dâhilinde bulunan başka bir üçüncü şahsın ayni veya şahsi haklarına, malvarlığına tecavüz teşkil edecek nitelikteki resimleri, metinleri, görsel ve işitsel imgeleri, video kliplerini, dosyaları, veri tabanlarını, katalogları ve listeleri çoğaltmayacağını, kopyalamayacağını, dağıtmayacağını, işlemeyeceğini kabul eder.</p>
    <p>18. MOBİL ASSİSTANCE sigorta faaliyetlerinde bulunmadığı gibi, müşterilerinin araçlarını sigortalamaz, sadece yolda kalmadan önce satın almış olduğunuz hizmeti, ihtiyaç duyduğunuzda kullanmanız için yol yardım ve ikame araç hizmeti verir, bu sebeple paket dahilinde hiçbir hizmet için araçta oluşacak zararı karşılamaz, müşterinin, MOBİL ASSİSTANCE aramayarak kendi isteğiyle aracı başka firmalara çektirmesi veya gelen servis sağlayıcısını herhangi bir sebep olmaksızın beklememesi durumunda zararı karşılama amacıyla para ödemesi yapmaz. Müşterinin aracı MOBİL ASSİSTANCE göndermiş olduğu çekicinin üzerinde iken hasar alması durumunda, gelmiş olan çekicinin sigortasına tabidir ve bu sigorta tarafından müşterinin zararı karşılanır bu durumdan dolayı MOBİL ASSİSTANCE sorumluluğu yoktur. MOBİL ASSİSTANCE doğrudan veya anlaşmalı olduğu servis sağlayıcıları tarafından iş bu sözleşmede bulunan yol yardım hizmetlerini vermekle yükümlüdür.</p>
    <p>19. Sözleşme başlangıç tarihinden itibaren 3 gün içerisinde iptali veya araç değişikliği talep edilmemesi durumunda sözleşme iptali ve araç değişikliği yapılmayacaktır</p>
    <p>20. MOBİL ASSİSTANCE, bölgedeki anlaşmalı mevcut çekme / kurtarma hizmet sağlayıcı firmaların imkanları, yeterlilikleri ölçüsünde, fiziki, coğrafi ve iklim koşullarının elverdiği ölçüde hizmetin verilmesini veya organizasyonunu gerçekleştirecektir</p>
    <p>21. Çekme veya kurtarma için, bölgedeki çekici / kurtarıcı firmalara ait çekici araçların ulaşabileceği, emniyetle yürüyebileceği yollar üzerinde olması, kapalı otoparklarda olmaması halinde hizmet sağlanacaktır. </p>
    <p>22. Aracın çalışmasına veya yürür üne engel bir mekanik arıza veya kaza durumunda hizmet verilecektir. (Aracın çamura saplanması, farlarının aydınlatmaması, cam silgiçlerinin çalışmaması, mazot ve motor donması, aracın karlı ve yağışlı havalarda yolda ilerleyemiyor olması, cam kırılması) mekanik arıza olarak değerlendirilmeyecek ve hizmet verilmeyecektir.</p>
    <p>23. – Hizmetten yararlanacak müşterinin oto tamirhane dışındaki başka bir adrese veya konuma aracın çekilmesi talepleri ( Sanayi bölgesinden evinin önüne veya her hangi bir oto tamir servisinden başka bir oto tamir servisine çekilmesi talepleri) karşılanmayacaktır. </p>
    <p>24. İç işleri bakanlığı tarafından terör bölgesi ve özel güvenlik bölgesi ilan edilen il ve ilçelerde ve Grev, Kargaşalık, Halk hareketleri olması durumunda hizmet verilmeyecektir. </p>

    <h2>GİZLİLİK POLİTİKASI VE İZİNLER</h2>
    <p>1. www.mobilassistance.com. internet sitesi ziyaretçi ve üyeleri (bundan sonra KULLANICILAR olarak anılacaktır) siteye erişmekle veya siteyi kullanmakla, ÜYELİK SÖZLEŞMESİ ile birlikte işbu GİZLİLİK SÖZLEŞMESİ’ ni de peşinen kabul etmiş olurlar.</p>
    <p>2. İşbu GİZLİLİK SÖZLEŞMESİ; www.mobilassistance.com. sahibi MOBİL ASSİSTANCE (bundan sonra kısaca MOBİL ASSİSTANCE olarak anılacaktır) daha iyi hizmet verebilmek amacıyla KULLANICILARDAN elde ettiği bilgilerin mahiyetiyle bu bilgilerin ne şekilde kullanılacağını ve nasıl korunacağını açıklamaktadır.</p>
    <p>3. MOBİL ASSİSTANCE, müşterilerine daha iyi hizmet verebilmek amacıyla KULLANICILARIN bazı kişisel bilgilerini (isim, soy isim, araç plakası, rengi, modeli, coğrafi konumu, telefon numarası vb.) talep etmektedir. Bu kişisel bilgilerden elde edilebilecek istatistiki veriler (tarayıcı tipi, coğrafi konum, yaş, cinsiyet, vb.) dönemsel kampanya çalışmaları, e-bülten çalışmaları, müşteri profillerine yönelik özel promosyon faaliyetlerinin kurgulanması ve istenmeyen e-postaların iletilmemesine yönelik müşteri "sınıflandırma" çalışmalarında MOBİL ASSİSTANCE tarafından kullanılmaktadır.</p>
    <p>4. MOBİL ASSİSTANCE; üyelik formlarından topladığı bilgileri, söz konusu üyenin haberi ya da aksi bir talimatı olmaksızın, üçüncü şahıslarla kesinlikle paylaşmamakta, faaliyet dışı hiçbir nedenle ticari amaçla kullanmamakta ve de satmamaktadır.</p>
    <p>5. MOBİL ASSİSTANCE; e-posta adresleri ve üyelik formlarında istediği kişisel bilgilerin haricinde site kullanımı sırasında izlediği ziyaretçi hareket ve tercihlerini analiz ederek yorumlamaktadır. Kişisel bilgiler içermeyen bu istatistiksel veriler, KULLANICILARA daha özel ve etkin hizmet sunmak amacıyla kullanılmaktadır.</p>
    <p>6. KULLANICILARIN bilgileri, ancak resmi makamlarca usulü dairesinde bu bilgilerin talep edilmesi halinde ve yürürlükteki emredici mevzuat hükümleri gereğince MOBİL ASSİSTANCE resmi makamlara açıklama yapmak zorunda olduğu durumlarda resmi makamlara açıklanabilecektir.</p>
    <p>7. KULLANICILARDAN ödeme işlemleri sırasında istenen kredi kartı bilgileri, güvenliği en üst seviyede tutmak amacıyla KULLANICI tercihine bağlı olarak 3D Secure uygulaması ile gerçekleştirilebilmektedir. Bu şekilde ödemeye yönelik tüm işlemlerin mobilassistance.com ara yüzü üzerinden banka ve bilgisayar / mobil cihaz arasında gerçekleşmesi sağlanmaktadır.</p>
    <p>8. MOBİL ASSİSTANCE tarafından toplanan bütün kişisel bilgiler genel kullanıma açık olmayan güvenli bir ortamda saklanır.</p>
    <p>9. www.mobilassistance.com sitesi üzerinde yer alan üçüncü parti sitelere ait bağlantılara (linklere) tıklayarak bu siteleri ziyaret eden KULLANICILAR, o sitelerdeki bütün faaliyet ve işlemleriyle ilgili olarak yine o sitelerde yer alan kullanıcı ve gizlilik sözleşmelerine bağlı olurlar. KULLANICILARIN üçüncü parti sitelerdeki işlemlerinden doğan zararlarından, doğrudan ya da dolaylı olarak, hiçbir şekilde MOBİL ASSİSTANCE sorumlu tutulamaz.</p>
    
    <h2>FİKRİ MÜLKİYET HAKLARI </h2>
    <p>Uygulamanın tasarım, metin, imge, html kodu ve diğer kodlar da dâhil ve fakat bunlarla sınırlı olmamak kaydıyla, tüm unsurları (bundan böyle “MOBİL ASSİSTANCE telif haklarına tabi çalışmalar” olarak anılacaktır) MOBİL ASSİSTANCE aittir. Müşteri, MOBİL ASSİSTANCE Hizmetleri’ni, MOBİL ASSİSTANCE bilgilerini ve MOBİL ASSİSTANCE’ telif haklarına tabi çalışmalarını ticari amaçla kullanamaz. MOBİL ASSİSTANCE izni olmaksızın, MOBİL ASSİSTANCE telif haklarına tabi çalışmalarını çoğaltamaz, dağıtamaz veya bunlardan türemiş çalışmalar yapamaz veya hazırlayamaz. MOBİL ASSİSTANCE; MOBİL ASSİSTANCE Hizmetleri, MOBİL ASSİSTANCE bilgileri, MOBİL ASSİSTANCE telif haklarına tabi çalışmaları, MOBİL ASSİSTANCE ticari markaları, MOBİL ASSİSTANCE ticari görünümü veya Uygulama vasıtasıyla sahip olduğu her tür maddi ve fikri mülkiyet hakları da dâhil tüm malvarlığı, ayni ve şahsi hakları, ticari bilgi ve know-how’a yönelik tüm hakları saklıdır.</p>


    <h2>SÖZLEŞME DEĞİŞİKLİKLERİ;</h2>
    <p>MOBİL ASSİSTANCE, tamamen kendi takdirine bağlı ve tek taraflı olarak, sözleşmeyi, uygun göreceği herhangi bir zamanda, web sitesi üzerinden ilan ederek değiştirebilir. İşbu Koşulların değişen hükümleri, ilan edildikleri tarihte veya sonrasında, Müşteri tarafından Uygulama üzerinden on-line kabul edilmesi ile geçerlilik kazanacak, geri kalan hükümler aynen yürürlükte kalarak hüküm ve sonuçlarını doğurmaya devam edecektir.</p>

    <h2>İŞ ORTAKLARI, YETKİLİ SERVİSLER VERİ DEĞİŞİKLİKLERİ;</h2>
    <p>MOBİL ASSİSTANCE, uhdesinde bulunan SERVİS Sağlayıcıların adres, telefon ve sair verilerinin değişikliklerinden kaynaklanabilecek zarardan sorumlu tutulamaz.</p>


    <h2>REKLAM SERVİSLERİ;</h2>
    <p>MOBİL ASSİSTANCE, Uygulamada bulunan her türlü reklam yönlendirmesinin sadece reklam amaçlı olduğunu ve Reklam alınan firmaların vereceği hizmet kalitesinden ve bu firmaların yaptıkları işlerden doğacak zararlardan hiçbir şekilde sorumlu tutulamaz.</p>
    
    <h2>MÜCBİR SEBEPLER;</h2>
    <p>Hukuken mücbir sebep sayılan tüm durumlarda MOBİL ASSİSTANCE işbu Koşullar ile belirtilen hizmetlerin herhangi birini geç veya eksik ifa etme veya ifa etmeme nedeniyle yükümlü değildir. Bu ve bunun gibi durumlar MOBİL ASSİSTANCE için, gecikme, eksik ifa etme veya ifa etmeme veya temerrüt addedilmeyecek veya bu durumlar için MOBİL ASSİSTANCE herhangi bir nam altında tazminat talep edilemeyecektir. “Mücbir sebep” terimi; doğal afet, isyan, savaş, grev, iletişim sorunları, altyapı ve internet arızaları, elektrik kesintisi ve kötü hava koşulları da dâhil ve fakat bunlarla sınırlı olmamak kaydıyla; MOBİL ASSİSTANCE makul kontrolü haricinde ve MOBİL ASSİSTANCE gerekli özeni göstermesine rağmen önleyemediği, kaçınılamayacak olaylar olarak yorumlanacaktır.</p>

    <h2>UYGULANACAK HUKUK VE YETKİ;</h2>
    <p>İşbu Koşullar’ın uygulanmasında, yorumlanmasında ve işbu Koşullar dâhilinde doğan hukuki ilişkilere Türk Hukuku uygulanacak ve İşbu Koşullar dahilinde ve/veya bunlarla bağlantılı olarak doğan veya doğabilecek her türlü ihtilafın varlığı halinde KAYSERİ Merkez Mahkemeleri ve İcra Daireleri yetkilidir.</p>

    <h2>SÖZLEŞMENİN FESHİ; </h2>
    <p>İşbu Koşullar, Müşteri’nin üyeliği sona erinceye kadar yürürlükte kalacak ve Taraflar arası hüküm ve sonuçlarını doğurmaya devam edecektir. MOBİL ASSİSTANCE, Müşteri’nin işbu Koşullar dâhilinde belirtilen hükümlere ve Uygulama içinde yer alan kullanıma, üyeliğe ve MOBİL ASSİSTANCE Hizmetleri’ne ilişkin benzeri kurallara aykırı hareket etmesi durumunda; sözleşmeyi tek taraflı olarak feshedebilecektir.</p>

    <h2>MOBİL ASSİSTANCE KAYITLARININ GEÇERLİLİĞİ;</h2>
    <p>Müşteri, işbu Koşullar dâhilinde doğabilecek ihtilaflarda, MOBİL ASSİSTANCE ‘in kendi veri tabanında veya sunucularında tuttuğu elektronik kayıtların ve sistem kayıtlarının, ticari kayıtlarının, defter kayıtlarının, mikrofilm, mikrofiş ve bilgisayar kayıtlarının muteber, bağlayıcı, kesin ve münhasır delil teşkil edeceğini; MOBİL ASSİSTANCE i yemin teklifinden ari kıldığını ve bu maddenin HMK 193. madde anlamında delil sözleşmesi niteliğinde olduğunu kabul eder.</p>

    <h2>YÜRÜRLÜK</h2>
    <p>İşbu Sözleşme Müşteri’nin elektronik olarak onay vermesi ile karşılıklı olarak kabul edilerek yürürlüğe girmiştir.</p>
    </div>
    </>
    
)
};

export default Mss;