import { Routes, Route } from 'react-router-dom';
import Homepage from './views/pages/main'

import KurumsalMain from './views/pages/kurumsal/kurumsalmain'
import Kurumsal from './views/pages/kurumsal/kurumsal'
import Hedefler from './views/pages/kurumsal/kurumsalhedefler'
import KalitePolitikasi from './views/pages/kurumsal/kalitepolitikasi'
import Tarihce from './views/pages/kurumsal/tarihce'
import Etik from './views/pages/kurumsal/etik'
import TanitimFilmi from './views/pages/kurumsal/tanitimfilmi'
import Belgeler from './views/pages/kurumsal/belgeler'

import InsanKaynaklari from './views/pages/insankaynaklari';

import Iletisim from './views/pages/iletisim';

import OnlineMain from './views/pages/onlineislemler/OnlineIslemler';
import OnlineSozlesmeAra from './views/pages/onlineislemler/sozlesmeara';
import BayilikBasvurusu from './views/pages/onlineislemler/bayibasvuru';
import TedarikciBasvurusu from './views/pages/onlineislemler/tedarikcibasvuru';
import BayiAgi from './views/pages/onlineislemler/bayiagi';
import Paketler from './views/pages/paketler/paketler';
import YolYardims from './views/pages/sehirler';
import SehirDetay from './views/pages/sehirDetay';
import PaketMotosiklet from './views/pages/paketler/paketmotosiklet';
import PaketOtomobil from './views/pages/paketler/paketotomotiv';
import PaketHafifTicari from './views/pages/paketler/pakethafifticari copy';
import PaketAgirTicari from './views/pages/paketler/paketagirticari';
import PaketIkame from './views/pages/paketler/paketikamearac';
import Hizmetler from './views/pages/hizmetler/hizmetlermain';
import YolYardimHizmeti from './views/pages/hizmetler/yolyardim'
import IkameAracHizmeti from './views/pages/hizmetler/ikamearac'
import CagriMerkeziHizmeti from './views/pages/hizmetler/cagrimerkezi'
import KisiyeOzelHizmet from './views/pages/hizmetler/kisiyeozel'
import KurumsalK from './views/pages/kurumsal/kurumsalkimlik';

function App() {

  return (
    <Routes>
      <Route path="/" element={<Homepage />}></Route>
      <Route path="*" element={<Homepage />}></Route>
      <Route path="/tr/kurumsal/mobil-assistance-kurumsal" element={<KurumsalMain />}></Route>
      <Route path="/tr/kurumsal/mobil-assistance-hakkinda" element={<Kurumsal />}></Route>
      <Route path="/tr/kurumsal/kurumsal-hedefler" element={<Hedefler />}></Route>
      <Route path="/tr/kurumsal/tr/kurumsal/kalite-politikamiz" element={<KalitePolitikasi />}></Route>
      <Route path="/tr/kurumsal/tarihcemiz" element={<Tarihce />}></Route>
      <Route path="/tr/kurumsal/etik-kurallarimiz" element={<Etik />}></Route>
      <Route path="/tr/kurumsal/tanitim-filmi" element={<TanitimFilmi />}></Route>
      <Route path="/tr/kurumsal/kalite-belgelerimiz" element={<Belgeler />}></Route>
      <Route path='/tr/kurumsal/kurumsal-kimlik' element={<KurumsalK/>}></Route>

      {/* İnsan Kaynakları */}
      <Route path="/tr/mobil-assistance/insan-kaynaklari" element={<InsanKaynaklari />}></Route>

      {/* İletişim */}
      <Route path="/tr/mobil-assistance/iletisim" element={<Iletisim />}></Route>

      {/* Seo */}
      <Route path="/tr/mobil-assistance/sehirler" element={<YolYardims />}></Route>

      {/* Online İşlemler */}
      <Route path='/tr/online-islemler' element={<OnlineMain />}></Route>
      <Route path='/tr/online-islemler/sozlesme-ara' element={<OnlineSozlesmeAra />}></Route>
      <Route path='/tr/online-islemler/bayilik-basvurusu' element={<BayilikBasvurusu />}></Route>
      <Route path='/tr/online-islemler/tedarikci-basvurusu' element={<TedarikciBasvurusu />}></Route>
      <Route path='/tr/online-islemler/sozlesme-ara' element={<TedarikciBasvurusu />}></Route>
      <Route path='/tr/online-islemler/bayi-agimiz' element={<BayiAgi />}></Route>

      <Route path='/tr/mobil-assistance/yol-yardim' element={<SehirDetay />}></Route>

      {/* Paketler */}
      <Route path='/tr/paketler' element={<Paketler />}></Route>
      <Route path='/tr/paketler-motosiklet-yol-yardim-paketleri' element={<PaketMotosiklet />}></Route>
      <Route path='/tr/paketler-otomobil-yol-yardim-paketleri' element={<PaketOtomobil />}></Route>
      <Route path='/tr/paketler-hafif-ticari-yol-yardim-paketleri' element={<PaketHafifTicari />}></Route>
      <Route path='/tr/paketler-agir-ticari-yol-yardim-paketleri' element={<PaketAgirTicari />}></Route>
      <Route path='/tr/paketler-ikame-arac-yol-yardim-paketleri' element={<PaketIkame />}></Route>

      {/* Hizmetlerimiz */}
      <Route path='/tr/hizmetlerimiz' element={<Hizmetler />}></Route>
      <Route path='/tr/hizmetlerimiz/yol-yardim-kurtarma-hizmeti' element={<YolYardimHizmeti />}></Route>
      <Route path='/tr/hizmetlerimiz/ikame-arac-hizmeti' element={<IkameAracHizmeti />}></Route>
      <Route path='/tr/hizmetlerimiz/cagri-merkezi-hizmeti' element={<CagriMerkeziHizmeti />}></Route>
      <Route path='/tr/hizmetlerimiz/kisiye-ozel-hizmet-paketleri' element={<KisiyeOzelHizmet />}></Route>
    </Routes>
  );
}

export default App;
